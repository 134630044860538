import { createApp, h } from 'vue'
import App from './App.vue'

import * as mongoService from '@/database/mongo'

import { getI18NFn } from '@/utils/string'

import io from 'socket.io-client'

import Toaster from '@meforma/vue-toaster'
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'

const params = new URLSearchParams(window.location.search)

const appId = params.get('app_id')
const userDbId = params.get('user_db_id')
const initRoomId = params.get('init_room_id')
const customFileUploadYn = params.get('custom_file_uploadYn')
const roomType = params.get('room_type')
const swingRoomId = params.get('swing_room_id')
let langCode = params.get('lang_code')

let gohostModeYn = params.get('gohost_mode_yn')

let isRoomPageingYn = params.get('is_room_pagingYn')
let roomPagingRange = params.get('room_paging_range')


let blockUserYn = params.get('block_user_yn')
if (blockUserYn !== 'Y') {
  blockUserYn = 'N'
}
blockUserYn='Y';

if (isRoomPageingYn !== 'N') {
  isRoomPageingYn = 'Y'
  try {
    roomPagingRange = parseInt(roomPagingRange)
  } catch (e) {
    roomPagingRange = 50
  }
} else {
  roomPagingRange = 0
}

// eslint-disable-next-line eqeqeq
if (langCode != 'ko' && langCode != 'en') {
  langCode = 'ko'
}

if (gohostModeYn !== 'Y') {
  gohostModeYn = 'N'
}

// import mongoose from 'mongoose/browser';
// mongoose.connect('mongodb://admin806:asdfasdf1@localhost:27017/testDB')
// //
//
// mongoose.connect('mongodb://admin806:asdfasdf1@localhost:27017/testDB', {
//   useNewUrlParser: true,
//   useUnifiedTopology: true,
//   useFindAndModify: false,
//   useCreateIndex: true
// })
//   .then(() => {
//     console.log('Connected to the database!');
//   })
//   .catch(err => {
//     console.log('Cannot connect to the database!', err);
//   });

const messageCallback = function(data) {
  try {
    parent.postMessage({
      function: 'group_chat_command',
      data: data
    }, '*')
  } catch (e) {
    console.log(e)
  }
}
window.messageCallback = messageCallback

// eslint-disable-next-line camelcase
const eventTriggerFn = function(p_el, p_eventName) {
  let event
  if (document.createEvent) {
    event = document.createEvent('HTMLEvents')
    event.initEvent(p_eventName, true, true)
    // eslint-disable-next-line camelcase
    event.eventName = p_eventName
    p_el.dispatchEvent(event)
  } else {
    event = document.createEventObject()
    // eslint-disable-next-line camelcase
    event.eventName = p_eventName
    // eslint-disable-next-line camelcase
    event.eventType = p_eventName
    p_el.fireEvent('on' + event.eventType, event)
  }
}

// eslint-disable-next-line camelcase
const eventReplaceFn = function(p_el, p_eventName, p_eventFn) {
  try {
    let listenerFn = (window.getEventListenersByCustom) ? window.getEventListenersByCustom(p_el)[p_eventName][0].listener : undefined
    p_el.removeEventListener(p_eventName, listenerFn)
    p_el.addEventListener(p_eventName, p_eventFn)
  } catch (e) {
    console.log(e)
  }
}

const fileInputSetFn = function (fileObs) {
  const inputEl = document.querySelectorAll(".vac-box-footer input[type='file']")[0]

  const dataTransfer = new DataTransfer()
  if (fileObs) {
    for (let index = 0; index < fileObs.length; index++) {
      fileObs[index]['aaaaaaa'] = 'bbbbbb'
      dataTransfer.items.add(fileObs[index])
    }
  }
  inputEl.files = dataTransfer.files
  eventTriggerFn(inputEl, 'change')

  setTimeout(() => {
    let positionIdx = document.querySelectorAll('.vac-files-box .vac-room-file-container[swing-file-id]').length
    for (let index = 0; index < fileObs.length; index++) {
      let fileOb = fileObs[index]
      if (fileOb && fileOb.swingFileId) {
        let el = document.querySelectorAll('.vac-files-box .vac-room-file-container')[positionIdx + index]
        el.setAttribute('swing-file-id', fileOb.swingFileId)
        el.setAttribute('swing-file-url', fileOb.swingFileUrl)
      }
    }
  }, 100)

  // document.querySelectorAll(".vac-room-files-container .vac-files-box .vac-room-file-container")[0].closest("div").remove()
}

const getFileBlogFn = async function (fileUrl) {
  const fileResult = await fetch(fileUrl)
  const fileBlob = await fileResult.blob()
  return fileBlob
}

// eslint-disable-next-line camelcase
const fileAddFnBySwingS3 = function (p_fileUrlArr) {
  const rawFiles = []
  // eslint-disable-next-line camelcase
  if (p_fileUrlArr) {
    p_fileUrlArr.forEach(fileUrl => {
      const promise = getFileBlogFn(fileUrl)
      rawFiles.push(promise)
    })

    Promise.all(rawFiles)
      .then(function (fileResultArr) {
        let fileObs = []
        for (let index = 0; index < fileResultArr.length; index++) {
          let fileBlob = fileResultArr[index]
          // eslint-disable-next-line camelcase
          let lp_fileUrl = p_fileUrlArr[index]
          let fileName = lp_fileUrl.substring(lp_fileUrl.lastIndexOf('/') + 1)
          let fileOb = new File([fileBlob], fileName, fileBlob)
          let fileId = lp_fileUrl.substring(lp_fileUrl.replace('/' + fileName, '').lastIndexOf('/') + 1).replace('/' + fileName, '')
          fileOb.swingFileId = fileId
          // eslint-disable-next-line camelcase
          fileOb.swingFileUrl = lp_fileUrl
          fileObs.push(fileOb)
        }

        const vueOb = (window && window.chatthisOb) ? window.chatthisOb : undefined
        vueOb.fileInputSetFn(fileObs)
      })
  }
}

function timeoutFetch(promise) {
  const controller = new AbortController()

  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error('timeout'))
      // resolve()
    }, 2000)
    promise.then(resolve, reject)
  })
}

// eslint-disable-next-line camelcase
const sendSwingPushFn = async function (p_targetUserIdArr, p_content, p_roomInfo, p_senderSwingUserDbId, p_senderName, p_files) {
  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  let urlencoded = new URLSearchParams()
  urlencoded.append('appId', appId)

  // eslint-disable-next-line eqeqeq
  if (p_roomInfo.roomType == 'ADMIN_CHAT' || p_roomInfo.roomType == 'BOARD_CHAT') {
    urlencoded.append('chatId', p_roomInfo.swingRoomDbId)
  } else {
    urlencoded.append('chatId', p_roomInfo.id)
  }
  urlencoded.append('chatType', p_roomInfo.roomType)

  urlencoded.append('targetUserList', p_targetUserIdArr.toString())
  urlencoded.append('message', p_content)

  urlencoded.append('sendUserDbId', p_senderSwingUserDbId)
  urlencoded.append('sendUserName', p_senderName)

  // eslint-disable-next-line camelcase
  if (p_files) {
    urlencoded.append('files', p_files)
  }

  const controller = new AbortController()
  const timeout = setTimeout(() => {
    controller.abort()
  }, 2000)

  let requestOptions = {
    signal: controller.signal,
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  }
  // const swingPushApiUrl = 'http://hustay.asuscomm.com:8803/swapi/vue_chat_push_send'
  // const swingPushApiUrl = 'http://hustay.asuscomm.com:9081/v2_2015_11_21/chat_module/send_messag_notify'
  // const swingPushApiUrl = 'https://www.swing2app.co.kr/v2_2015_11_21/chat_module/send_messag_notify'
  const swingPushApiUrl = 'https://www.swing2app.co.kr/v2_2015_11_21/chat_module/send_message_notify'

  let response = await fetch(swingPushApiUrl, requestOptions)
  clearTimeout(timeout)
  let result = await response.text()

  return result
}

// eslint-disable-next-line camelcase
const swomgAppReportFn = async function (p_targetUserId, p_reportContent, p_reportUserId, p_reportObjectType, p_reportObjectId, p_reportMeta, p_stat, p_reportType, p_ogContent
) {
  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  let urlencoded = new URLSearchParams()
  urlencoded.append('app_id', appId)

  urlencoded.append('target_user_id', p_targetUserId)
  urlencoded.append('report_content', p_reportContent)
  urlencoded.append('report_user_id', p_reportUserId)

  let reportObjectType = ''
  // eslint-disable-next-line camelcase
  if (p_reportObjectType === 'BOARD_CHAT') {
    reportObjectType = 'GROUP_CHAT'
    // eslint-disable-next-line camelcase
  } else if (p_reportObjectType === 'ADMIN_CHAT') {
    reportObjectType = 'ADMIN_CHAT'
    // eslint-disable-next-line camelcase
  } else if (p_reportObjectType === 'USER_CHAT') {
    reportObjectType = 'USER_CHAT'
  }
  urlencoded.append('report_object_type', reportObjectType)

  urlencoded.append('report_object_id', p_reportObjectId)

  urlencoded.append('report_meta', p_reportMeta)
  urlencoded.append('stat', p_stat)
  urlencoded.append('report_type', p_reportType)
  urlencoded.append('og_content', p_ogContent)

  const controller = new AbortController()
  const timeout = setTimeout(() => {
    controller.abort()
  }, 2000)

  let requestOptions = {
    signal: controller.signal,
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  }
  // SDH__SERVER_TEST_ROW
  // const swingPushApiUrl = 'http://hustay.asuscomm.com:8803/swapi/app_report_fn'
  const swingPushApiUrl = 'https://www.swing2app.co.kr/swapi/app_report_fn'

  let response = await fetch(swingPushApiUrl, requestOptions)
  clearTimeout(timeout)
  let result = await response.json()

  return result
}

mongoService.initDataFn(appId, userDbId, initRoomId)
  // eslint-disable-next-line standard/object-curly-even-spacing
  .then(resultData => {
    const app = createApp({
      render: () => h(App)
    })

    app.use(Toaster, { position: 'top' })

    app.config.globalProperties.langCode = langCode
    app.config.globalProperties.gohostModeYn = gohostModeYn
    app.config.globalProperties.blockUserYn = blockUserYn
    if (resultData && resultData.userInfo) {
      app.config.globalProperties.swingAppId = appId
      app.config.globalProperties.myMongoId = resultData.userInfo._id
      app.config.globalProperties.MyUserInfo = resultData.userInfo
    }
    if (resultData && resultData.appInfoData) {
      app.config.globalProperties.appInfoData = resultData.appInfoData
    }

    if (initRoomId && resultData && resultData.roomInfo) {
      // roomInfo
      app.config.globalProperties.initRoomId = initRoomId
      app.config.globalProperties.initRoomInfo = resultData.roomInfo
    } else if (initRoomId && (!resultData || !resultData.roomInfo)) {
      alert('방이 존재하지 않습니다.')
      return false
    }

    // alert('matchAll : ' + typeof (String.prototype.matchAll) + '\nRegExp : ' + typeof (RegExp) + '\nreplaceAll : ' +
    //   typeof (String.prototype.replaceAll) + '\nResizeObserver : ' + typeof (ResizeObserver))

    if (typeof (String.prototype.matchAll) === 'undefined') {
      const matchAllPlug = require('string.prototype.matchall')
      // eslint-disable-next-line no-extend-native
      String.prototype.matchAll = matchAllPlug
    }
    if (typeof (String.prototype.replaceAll) === 'undefined') {
      // eslint-disable-next-line no-extend-native
      String.prototype.replaceAll = function(match, replace) {
        return this.replace(new RegExp(match, 'g'), () => replace)
      }
    }

    if (typeof global !== 'undefined') {
      global.ResizeObserver = global.ResizeObserver || ResizeObserverPolyfill
      // global.ResizeObserver = ResizeObserverPolyfill
      global.isResizeObserverCustomYn = 'Y'
    }

    // eslint-disable-next-line eqeqeq
    if (customFileUploadYn == 'Y') {
      app.config.globalProperties.customFileUploadYn = customFileUploadYn
    }
    app.config.globalProperties.swingRoomType = roomType

    app.config.globalProperties.eventTriggerFn = eventTriggerFn
    app.config.globalProperties.eventReplaceFn = eventReplaceFn
    app.config.globalProperties.fileInputSetFn = fileInputSetFn
    app.config.globalProperties.fileAddFnBySwingS3 = fileAddFnBySwingS3
    app.config.globalProperties.sendSwingPushFn = sendSwingPushFn
    app.config.globalProperties.swomgAppReportFn = swomgAppReportFn

    app.config.globalProperties.isRoomPageingYn = isRoomPageingYn
    app.config.globalProperties.roomPagingRange = roomPagingRange

    const varUA = navigator.userAgent.toLowerCase()

    if (varUA.indexOf('android') > -1) {
      app.config.globalProperties.userOsVal = 'android'
    } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
      app.config.globalProperties.userOsVal = 'ios'
    } else {
      app.config.globalProperties.userOsVal = 'other'
    }
    // const vm = new Vue({
    //   render: h => h(App)
    // }).$mount('#app')

    window.appVm = app
    window.getEventListenersByCustom = window.getEventListeners
    // window.appVm.$root.sendMessageFn("ccc")

    // SDH_SERVER_TEST_ROW
const $socket = io(location.protocol + '//' + location.hostname + ':8085/'
// const $socket = io('ws://hustay.asuscomm.com:18085/'
// const $socket = io('ws://ec2-52-79-240-26.ap-northeast-2.compute.amazonaws.com:8085/'
// const $socket = io('ws://ec2-15-164-100-102.ap-northeast-2.compute.amazonaws.com:8085/'
// const $socket = io('ws://chat.swing2app.co.kr:8085/'
      , {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 40
      }
    )
    app.config.globalProperties.$socket = $socket

    $socket.on('connect', () => {
      console.log('socket-connect')
      // if (window && window.appVm && window.appVm.$toast && window.appVm.$toast.info) {
      //   window.appVm.$toast.info('is connect : ' + $socket.connected)
      // }

      const thisOb = (window && window.chatthisOb) ? window.chatthisOb : undefined

      // eslint-disable-next-line eqeqeq
      if (thisOb && thisOb.selectedRoom && thisOb.selectedRoom != '') {
        if (thisOb && thisOb.messages && thisOb.messages.length > 0) {
          const nowLoadedMessage = thisOb.messages[thisOb.messages.length - 1]
          const nowLoadedMessageTimestamp = nowLoadedMessage ? nowLoadedMessage.createdAt : null
          mongoService
            .getMessagesByAfter(thisOb.selectedRoom, nowLoadedMessageTimestamp)
            .then(data => {
              if (data && data.cnt && data.cnt > 0) {
                setTimeout(() => (thisOb.roomId = ''), 0)
                setTimeout(() => (thisOb.roomId = thisOb.selectedRoom), 100)
                // const foundRoom = thisOb.rooms.find(r => r.roomId === thisOb.selectedRoom)
                // if (foundRoom) {
                //   foundRoom.typingUsers = []
                // }
              }
            })
        }
      }

      // if (thisOb && thisOb.selectedRoom) {
      //   $socket.emit('add_client_by_user', {
      //     app_id: appId,
      //     user_db_id: userDbId,
      //     room_id: thisOb.selectedRoom
      //   })
      // }

      $socket.emit('add_client_by_user', {
        app_id: appId,
        user_db_id: userDbId,
        room_id: (thisOb && thisOb.selectedRoom) ? thisOb.selectedRoom : null
      })
    })

    app.mount('#app')
  })
  // eslint-disable-next-line handle-callback-err
  .catch((err) => {
    console.error(err)
    // alert(getI18NFn(langCode, 'm014'))
  })
