
const stringsKo = {
    m001: 'Type message',
    m002: '대화방이 존재하지 않습니다',
    m003: '선택된 대화방이 없습니다',
    m004: '새로운 메시지',
    m005: '메시지가 삭제되었습니다.',
    m006: '메시지가 없습니다.',
    m007: '대화방 시작:',
    m008: '메시지를 입력',
    m009: '검색',
    m010: '마지막 시간 ',
    m011: '입력중...',
    m012: '취소',
    m013: '취소',
    m014: '정보를 확인해주세요{0}-{1}',
    m015: '신고하기',
    m016: '답장',
    m017: '수정하기',
    m018: '삭제하기',
    m019: 'help',
    m020: 'This is the help',
    m021: 'action',
    m022: 'This is the action',
    m023: 'action 2',
    m024: 'This is the second action',
    m025: '신고 완료',
    m026: '신고 실패',
    m027: '신고할 내용을 입력해주세요',
    m028: '용량은 {0}MB를 초과할 수 없습니다.',
    m029: '검색어는 최소 2자이상으로 검색해주세요',
    m030: '회원차단',
    m031: '자신을 차단할수 없습니다.'
}

const stringsEn = {
  m001: 'Type message',
  m002: 'No rooms',
  m003: 'No room selected',
  m004: 'New Messages',
  m005: 'This message was deleted',
  m006: 'No messages',
  m007: 'Conversation started on:',
  m008: 'Type message',
  m009: 'Search',
  m010: 'last seen ',
  m011: 'is writing...',
  m012: 'Cancel',
  m013: 'Cancel',
  m014: 'Check Information{0}-{1}',
  m015: 'Report',
  m016: 'Reply',
  m017: 'Edit Message',
  m018: 'Delete Message',
  m019: 'help',
  m020: 'This is the help',
  m021: 'action',
  m022: 'This is the action',
  m023: 'action 2',
  m024: 'This is the second action',
  m025: 'Success',
  m026: 'Failure',
  m027: 'Please enter the information you want to report',
  m028: 'Maximum size is {0} MB',
  m029: 'Please search with at least 2 characters.',
  m030: 'Block member',
  m031: 'You cannot block yourself.'
}

// eslint-disable-next-line camelcase
const convertMessageArgument = function (value, p_args) {
  // eslint-disable-next-line eqeqeq
  if (p_args.length <= 2) {
    return value
  }

  var regex = /\{[0-9]\}/gi;
  value = value.replace(regex, function (mathing) {
    var num = Number(mathing.replace(/\{|\}/gi, ''))

    // eslint-disable-next-line standard/computed-property-even-spacing,eqeqeq
    return (p_args[ num + 2] == undefined ? mathing : p_args[ num + 2])
  })

  return value;
}

export const getI18NFn = function(langCode, langNum) {
  // eslint-disable-next-line eqeqeq
  const langMap = (langCode == 'ko') ? stringsKo : stringsEn
  let val = langMap[langNum]

  val = convertMessageArgument(val, arguments)
  // val += '@SDH@'

  return (val) || ''
}
