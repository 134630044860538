<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div class="search-header-box">
                <div class="search-icon" @click="$emit('modal-close')">
<img :src="require('@/assets/back-arrow-icon.svg')" />
</div>
                <input v-model="searchKeywordStrInput" class="search-input" placeholder="대화 내용 검색" @keyup.enter="onEnter" />
              </div>
            </slot>
          </div>

          <div
            id="messages-list"
            class="modal-body vac-container-scroll"
            @scroll="handleScroll"
          >
<!--            <loader :show="loadingMessages" type="messages">-->
<!--              <template v-for="(idx, name) in $slots" #[name]="data">-->
<!--                <slot :name="name" v-bind="data" />-->
<!--              </template>-->
<!--            </loader>-->

            <div class="vac-messages-container">
              <div :class="{ 'vac-messages-hidden': loadingMessages }">
                <transition name="vac-fade-message">
                  <div>
                    <div v-if="showNoMessages" class="vac-text-started">
                      <slot name="messages-empty">
                        {{ textMessages.MESSAGES_EMPTY }}
                      </slot>
                    </div>
                    <div v-if="showMessagesStarted" class="vac-text-started">
                      {{ textMessages.CONVERSATION_STARTED }} {{ messages[0].date }}
                    </div>
                  </div>
                </transition>
                <div
                  v-if="messages.length && !messagesLoaded && false"
                  id="infinite-loader-messages"
                >
                  <loader :show="true" :infinite="true" type="infinite-messages">
                    <template v-for="(idx, name) in $slots" #[name]="data">
                      <slot :name="name" v-bind="data" />
                    </template>
                  </loader>
                </div>
                <transition-group :key="selectRoomId" name="vac-fade-message" tag="span">
                  <div v-for="(m, i) in messages" :key="m.indexId || m._id">
                    <room-message
                      :current-user-id="currentUserId"
                      :message="m"
                      :index="i"
                      :messages="messages"
                      :edited-message-id="editedMessageId"
                      :message-actions="messageActions"
                      :text-messages="textMessages"
                      :new-messages="newMessages"
                      :show-reaction-emojis="showReactionEmojis"
                      :show-new-messages-divider="showNewMessagesDivider"
                      :text-formatting="textFormatting"
                      :link-options="linkOptions"
                      :username-options="usernameOptions"
                      :message-selection-enabled="messageSelectionEnabled"
                      :selected-messages="selectedMessages"
                      :search-keyword-str="searchKeyword"
                      @open-failed-message="$emit('open-failed-message', $event)"
                      @open-file-bymodal="openFileRoot"
                    >
                      <template v-for="(idx, name) in $slots" #[name]="data">
                        <slot :name="name" v-bind="data" />
                      </template>
                    </room-message>
                  </div>
                </transition-group>
              </div>
            </div>
</div>

          <div class="modal-footer">
            <slot name="footer">
              <div style="width: 90px;" />

              <div class="search-idx-info-box">
                <div v-if="searchSize>0">
{{ searchIdx+1 }} / {{ searchSize }}
</div>
                <div v-else>
검색결과 없음.
</div>
<!--                <div>{{ searchKeyword }}</div>-->
              </div>

              <div class="chat-move-btn-group-box">
                <div class="chat-move-btn" move-code="up" @click="moveChatSearchFn">
<img :src="require('@/assets/arrow_up_icon.svg')" />
</div>
                <div class="chat-move-btn" move-code="down" @click="moveChatSearchFn">
<img :src="require('@/assets/arrow_down_icon.svg')" />
</div>
              </div>
</slot>
          </div>
        </div>
      </div>

      <div name="vac-fade-preview" appear>
        <media-preview
          v-if="showMediaPreview"
          :file="previewFile"
          @close-media-preview="showMediaPreview = false"
        >
          <template v-for="el in slots" #[el.slot]="data">
            <slot :name="el.slot" v-bind="data" />
          </template>
        </media-preview>
      </div>

    </div>

  </transition>
</template>

<script>

  // import { RoomMessage, Loader } from 'vue-advanced-chat'

  import { parseTimestamp, formatTimestamp } from '@/utils/dates'

  import * as mongoService from '@/database/mongo'
  import {
    Timestamp
  } from 'firebase/firestore'

  import { getI18NFn } from '@/utils/string'

  import Loader from '/components/Loader/Loader'
  import RoomMessage from '/lib/Room/RoomMessage/RoomMessage'
  import MediaPreview from '/lib/MediaPreview/MediaPreview'

  // window.appVm.$root.$toast.info('공지사항!!!!!!!!!!!!!!', {duration:5000});

  // import { register } from './../../dist/vue-advanced-chat.es.js'
  // import { register } from './../../src/lib/index.js'

  export default {
    components: {
      Loader,
      RoomMessage,
      MediaPreview
    },
    props: {
      currentUserId: { type: String, required: true }
    },

    data() {
      return {
        // eslint-disable-next-line vue/no-unused-properties
        showModal: false,
        container: undefined,

        itemListSize: 50,
        editedMessageId: null,
        initReplyMessage: null,
        initEditMessage: null,
        infiniteState: null,
        loadingMessages: !false,
        observer: null,
        showLoader: true,
        loadingMoreMessages: false,
        scrollIcon: false,
        scrollMessagesCount: 0,
        newMessages: [],
        messageSelectionEnabled: false,
        selectedMessages: [],
        usernameOptions: { minUsers: 3, currentUser: '' },
        textFormatting: {
            disabled: false,
            italic: '_',
            bold: '*',
            strike: '~',
            underline: '°',
            multilineCode: '```',
            inlineCode: '`'
        },
        linkOptions: { disabled: false, target: '_blank', rel: null },
        messageActions: [
            { name: 'replyMessage', title: 'Reply' },
            { name: 'editMessage', title: 'Edit Message', onlyMe: true },
            { name: 'deleteMessage', title: 'Delete Message', onlyMe: true },
            { name: 'selectMessages', title: 'Select' }
          ],
        showReactionEmojis: true,
        showNoMessages: false,
        showNewMessagesDivider: true,
        messagesLoaded: false,

        selectRoomId: null,
        allUserMap: {},
        searchKeywordStrInput: '',
        searchKeyword: '',

        mediaPreviewEnabledVal: !true,
        messages: [],
        searchItemList: [],
        searchIdx: 0,
        searchSize: 0,

        showMediaPreview: false,
        previewFile: {},

        textMessages: {
          ROOMS_EMPTY: getI18NFn(this.langCode, 'm002'),
          ROOM_EMPTY: getI18NFn(this.langCode, 'm003'),
          NEW_MESSAGES: getI18NFn(this.langCode, 'm004'),
          MESSAGE_DELETED: getI18NFn(this.langCode, 'm005'),
          MESSAGES_EMPTY: getI18NFn(this.langCode, 'm006'),
          CONVERSATION_STARTED: getI18NFn(this.langCode, 'm007'),
          TYPE_MESSAGE: getI18NFn(this.langCode, 'm008'),
          SEARCH: getI18NFn(this.langCode, 'm009'),
          LAST_SEEN: getI18NFn(this.langCode, 'm010'),
          IS_TYPING: getI18NFn(this.langCode, 'm011')
        }
      }
    },

    computed: {
      showMessagesStarted() {
        return this.messages.length && this.messagesLoaded
      }
    },
    created() {
      const thisOb = this

      thisOb.selectRoomId = window.getSelectedRoomIdFn()
      thisOb.allUserMap = window.getAllUserMapFn()
    },
    updated() {
      const thisOb = this
      // thisOb.moveCenterFn()
    },
    mounted() {
      // this.addCss()
      const thisOb = this
      window.moveCenterFn = thisOb.moveCenterFn

      thisOb.container = document.querySelectorAll('.modal-body')[0]

      window.addEventListener('message', function (event) {
        // eslint-disable-next-line eqeqeq
       if (event.data.function === 'fileDownEvIntercepter') {
          const eventIntercepterYn = event.data.eventIntercepterYn
          thisOb.mediaPreviewEnabledVal = !(eventIntercepterYn === 'Y')
        }
      }, false)

      // setTimeout(() => {

      // }, 100)
    },

    methods: {

      onEnter(e) {
        const thisOb = this

        if (thisOb.searchKeywordStrInput.length < 2) {
          thisOb.$toast.info(getI18NFn(thisOb.langCode, 'm029', undefined))
          return false
        }

        thisOb.searchKeyword = thisOb.searchKeywordStrInput
        thisOb.searchIdx = 0
        thisOb.messages = []


        e.target.blur()

        thisOb.getSearchListFn(() => {
          if (thisOb.searchItemList) {
            thisOb.searchSize = thisOb.searchItemList.length
            thisOb.searchIdx = (thisOb.searchItemList.length > 0) ? (thisOb.searchItemList.length - 1) : 0
          }

          if (thisOb.searchItemList && thisOb.searchItemList.length > 0) {
            thisOb.moveSearchPointFn(thisOb.searchIdx)
          }
        })
      },
      // eslint-disable-next-line camelcase
      moveSearchPointFn(p_searchIdx) {
        const thisOb = this
        // thisOb.messages = []
        // eslint-disable-next-line camelcase
        thisOb.searchIdx = p_searchIdx

        const searchItem = thisOb.searchItemList[thisOb.searchIdx]
        const msgTimestamp = searchItem['ogTimestamp']
        const searchItemId = searchItem['_id']

        // setTimeout(() => {
        // selectRoomId, messagesPerPage, targetLoadedMessage
        mongoService.getMessagesByTargetBeforAfter(thisOb.selectRoomId, thisOb.itemListSize, msgTimestamp)
          .then((resultDate) => {
            let tempList = []
            // beforList afterList

            tempList = tempList.concat(resultDate.beforList)
            tempList = tempList.concat([searchItem])
            tempList = tempList.concat(resultDate.afterList)

            if (tempList.length > 0) {
              thisOb.messages = []
            }
            tempList.forEach(message => {
              let formattedMessage = message
              if (message._id !== searchItemId) {
                formattedMessage = thisOb.formatMessage(message)
              }

              thisOb.messages = thisOb.messages.concat([formattedMessage])
            })

            console.log(`msg cnt : ${thisOb.messages.length}`)
            setTimeout(() => {
              thisOb.moveCenterFn(searchItemId)
            }, 10)
          })

        // }, 5000)
      },

      moveChatSearchFn(e) {
        const thisOb = this
        const moveCode = e.currentTarget.getAttribute('move-code')

        if (thisOb.searchSize == 0) {
          return false
        }
        // eslint-disable-next-line eqeqeq
        let tempIdx = (moveCode == 'up') ? (thisOb.searchIdx - 1) : (thisOb.searchIdx + 1)
        if (tempIdx >= thisOb.searchItemList.length) {
          tempIdx = thisOb.searchItemList.length - 1
        } else if (tempIdx < 0) {
          tempIdx = 0
        }
        thisOb.searchIdx = tempIdx
        thisOb.moveSearchPointFn(thisOb.searchIdx)
        // move-code
      },

      // eslint-disable-next-line vue/no-unused-properties,camelcase
      moveCenterFn(p_selectId, firstItemYn) {
        const thisOb = this
        const pointEl = document.getElementById(p_selectId)
        if (pointEl) {
          // eslint-disable-next-line no-undef
          // thisOb.container.scrollTop = (pointEl.offsetTop - ((thisOb.container.offsetHeight / 2) + 50)) // 50=> 헤더
          if (firstItemYn === 'Y') {
            thisOb.container.scrollTop = (pointEl.offsetTop + 50) // 50=> 헤더
          } else {
            thisOb.container.scrollTop = (pointEl.offsetTop - ((thisOb.container.offsetHeight / 5) + 50)) // 50=> 헤더
          }

          pointEl.classList.add('search-point-chatbox')
          setTimeout(() => {
            pointEl.classList.remove('search-point-chatbox')
          }, 1000)
        }
      },

      getSearchListFn(cbFn) {
        const thisOb = this
        thisOb.searchItemList = []

        // getMessagesBySearch = async (roomId, messagesPerPage, msgKeyword
        mongoService.getMessagesBySearch(thisOb.selectRoomId, null, thisOb.searchKeyword)
          .then((resultDate) => {
            let tempList = []
            // beforList afterList
            resultDate.forEach(message => {
              const formattedMessage = thisOb.formatMessage(message)
              tempList.push(formattedMessage)
            })

              thisOb.searchItemList = tempList

            if (typeof cbFn === 'function') {
              cbFn()
            }
          })
      },

      handleScroll(e) {
        const thisOb = this
        const { scrollHeight, clientHeight, scrollTop } = thisOb.container
        const bottomScroll = scrollHeight - clientHeight - scrollTop
        // eslint-disable-next-line no-template-curly-in-string,eqeqeq
        if (scrollTop == 0 || bottomScroll == 0) {
          console.log(`handleScroll ${scrollHeight - clientHeight - scrollTop}`)

          if (thisOb.messages.length == 0) {
            return false
          }
          let searchItem = thisOb.messages[0]
          let listTypeCode = 'befor'
          // eslint-disable-next-line eqeqeq
          if (bottomScroll == 0) {
            listTypeCode = 'after'
            searchItem = thisOb.messages[thisOb.messages.length - 1]
          }

          const msgTimestamp = searchItem['ogTimestamp']

          mongoService.getMessagesByTargetScroll(thisOb.selectRoomId, thisOb.itemListSize, msgTimestamp, listTypeCode)
            .then((resultDate) => {
              if (resultDate && resultDate.length > 0) {
                if (listTypeCode === 'befor') {
                  resultDate = resultDate.reverse()
                }

                let tempList = []

                resultDate.forEach(message => {
                  let formattedMessage = thisOb.formatMessage(message)
                  tempList.push(formattedMessage)
                })

                // setTimeout(() => {
                if (listTypeCode === 'after') {
                  thisOb.messages = thisOb.messages.concat(tempList)
                } else {
                  // thisOb.container.style['overflow-y'] = 'hidden'

                  // const thisOb.container.offsetHeight thisOb.container.scrollTop
                  const beforHt = thisOb.container.querySelectorAll('.vac-messages-container')[0].offsetHeight
                  thisOb.messages = tempList.concat(thisOb.messages)
                  setTimeout(() => {
                    // thisOb.container.style['overflow-y'] = 'auto'

                    // thisOb.moveCenterFn(filrstId, 'Y')
                    const afterHt = thisOb.container.querySelectorAll('.vac-messages-container')[0].offsetHeight
                    thisOb.container.scrollTop = afterHt - beforHt
                  }, 10)
                }
                // }, 500)
              }
            })
        }
        return scrollHeight - clientHeight - scrollTop
      },

      formatMessage(message) {
        const dateTimestampOb = Timestamp.fromDate(new Date(message.timestamp))

        let seenVal = true

        let userImgUrl = (this.allUserMap && this.allUserMap[message.sender_id] && this.allUserMap[message.sender_id].avatar) ? this.allUserMap[message.sender_id].avatar : null
        // eslint-disable-next-line eqeqeq
        if (userImgUrl && (userImgUrl.indexOf('http://') == -1 && userImgUrl.indexOf('https://') == -1)) {
          userImgUrl = 'https://ncdn2.swing2app.co.kr' + userImgUrl
        }

        // eslint-disable-next-line eqeqeq
        if (message.sender_id == window.getMyMongoUserDbIdFn()) {
          userImgUrl = null
          // eslint-disable-next-line eqeqeq
        } else if (message.sender_id != window.getMyMongoUserDbIdFn() && !userImgUrl) {
          userImgUrl = require('@/assets/user.png')
        }

        let senderUserId = message.sender_id

        const senderUserInfoOb = (this.selectedRoomOb && this.selectedRoomOb.users) ? this.selectedRoomOb.users.find(u => u._id === senderUserId) : {}

        // console.log(`${(allUserMap && allUserMap[message.sender_id])?.username}`)

        const formattedMessage = {
          ...message,
          ...{
            senderId: senderUserId,
            _id: message.id,
            seconds: dateTimestampOb.seconds,
            timestamp: parseTimestamp(dateTimestampOb, 'HH:mm'),
            ogTimestamp: message.timestamp,
            date: parseTimestamp(dateTimestampOb, 'DD MMMM YYYY'), // date format
            // username: room.users.find(user => message.sender_id === user._id)?.username,
            username: (this.allUserMap && this.allUserMap[message.sender_id])?.username,
            // avatar: senderUser ? senderUser.avatar : null,
            distributed: true,
            lastMessage: { ...message.lastMessage, senderId: message.sender_id },
            seen: seenVal,
            avatar: (userImgUrl) || undefined
          }
        }

        if (message.replyMessage) {
          // eslint-disable-next-line no-mixed-spaces-and-tabs
          if (!message.replyMessage.content) { message.replyMessage.content = '' }
          formattedMessage.replyMessage = {
            ...message.replyMessage,
            ...{
              senderId: message.replyMessage.sender_id
            }
          }
        }

        return formattedMessage
      },
      openFileRoot({ message, file }) {
        // this.previewFile = file.file
        // this.showMediaPreview = true

        // alert('openFileRoot')
        // eslint-disable-next-line no-mixed-spaces-and-tabs
        if (this.mediaPreviewEnabledVal === true) {
          console.log('open-file')
          window.open(file.file.url, '_blank')
        } else {
          //  file.action === 'preview' download
          console.log(`openFileEvent => ${file.file.url} ${file.action}`)
          window.parent.postMessage({
            function: 'openFileEvent',
            fileUrl: file.file.url,
            action: file.action
          }, '*')
        }
      }

    }
  }
</script>

<style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;

    display: flex;
  }

  .modal-wrapper {
    /*display: table-cell;*/
    vertical-align: middle;

    display: flex;
    /*width: 100%;*/
    width:calc(100% - 20px);

    /*padding: 20px 30px 30px;*/
    padding: 10px;
  }

  .modal-container {
    /*width: 300px;*/
    margin: 0px auto;
    /*padding: 20px 30px;*/
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;

    width: 100%;
    height: 100%;

    position: relative;
    display: flex;
    flex-flow: column;

    .modal-header {
      position: absolute;
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      z-index: 10;

      .search-header-box {
        display: flex;
        width: 100%;
        padding: 0 20px 0 10px;
        gap: 3px;
        .search-icon {
          align-items: center;
          display: flex;
          padding: 0 10px;
          img{
            width: 20px;
          }
        }

        .search-input {
          background: #fff;
          border-radius: 0;
          border: 1px solid #dce1e4;
          box-shadow: none !important;
          font-size: 13px;
          padding: 6px 10px !important;
          -webkit-transition: all .2s ease-in-out;
          -moz-transition: all .2s ease-in-out;
          -o-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;

          display: block;
          width: 100%;
          height: 22px;

          line-height: 1.42857143;
          color: #555;
          flex: 1;
        }
      }
    }
    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin-top: 51px;
      /*margin: 20px 0;*/
      flex: 1;
      overflow-y: auto;
      background-color: #f8f9fa;

      padding: 10px 15px;
      overflow-x: hidden;
    }

    .modal-footer {
      /*width: 100%;*/
      border-top: 1px solid #5f5f5f;
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      .chat-move-btn-group-box{
        display: flex;
        align-items: center;
        gap: 5px;
        .chat-move-btn{
          padding: 5px;

        }
      }

      .search-idx-info-box{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
      }

    }

  }
  .modal-default-button {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    /*font-size: 14px;*/
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #adadad;
    border-radius: 4px;

    font-size: 13px;
    /*border-radius: 0;*/
    outline: 0 !important;
    height: 20px;
    line-height: 20px;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  #pointEl{background-color: red;}

</style>
