<template>
	<div>
		<div
			class="app-container"
			:class="{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }"
		>
			<chat-container
				v-if="showChat"
				:current-user-id="currentUserId"
				:theme="theme"
				:is-device="isDevice"
			/>

			<!-- <div class="version-container">
				v1.0.0
			</div> -->

      <chat-modal
        v-if="isModalShow"
        :current-user-id="currentUserId"
        @modal-close="modalCloseFn"
      />
</div>
	</div>
</template>

<script>
import ChatContainer from './ChatContainer'
import ChatModal from './ChatModal'

export default {
	components: {
		ChatContainer,
    ChatModal
	},

	data() {
    return {
      theme: 'light',
      showChat: true,
      currentUserId: this.myMongoId,
      isDevice: false,
      showDemoOptions: true,
      // eslint-disable-next-line vue/no-unused-properties
      updatingData: false,
      isModalShow: false

    }
  },

	computed: {
		showOptions() {
			return !this.isDevice || this.showDemoOptions
		}
	},
	watch: {
		currentUserId() {
			this.showChat = false
      setTimeout(() => (this.showChat = true), 150)
		}
	},
  created() {
    const thisOb = this
    window.toggleModalFn = thisOb.toggleModalFn
  },

	mounted() {
    const thisOb = this

		this.isDevice = window.innerWidth < 900
		window.addEventListener('resize', ev => {
			if (ev.isTrusted) this.isDevice = window.innerWidth < 900
		})

    window.addEventListener('message', function(event) {
      if (event.data.function === 'getIsModalOpenYn') {
        window.parent.postMessage({
          function: 'isModalOpen',
          isModalOpenYn: thisOb.isModalShow ? 'Y' : 'N'
        }, '*')
      } else if (event.data.function === 'modalCloseFn') {
        thisOb.modalCloseFn()
      }
    }, false)
	},

	methods: {
    // eslint-disable-next-line no-mixed-spaces-and-tabs
	  toggleModalFn() {
      this.isModalShow = !this.isModalShow
    },
    modalCloseFn() {
      this.isModalShow = false
    }
	}
}
</script>

<style lang="scss">
body {
	background: #fafafa;
	margin: 0;
}

input {
	-webkit-appearance: none;
}

.app-container {
	font-family: 'Quicksand', sans-serif;
	padding: 20px 30px 30px;
}

.app-mobile {
	padding: 0;

	&.app-mobile-dark {
		background: #131415;
	}

	.user-logged {
		margin: 10px 5px 0 10px;
	}

	select {
		margin: 10px 0;
	}

	.button-theme {
		margin: 10px 10px 0 0;

		.button-github {
			height: 23px;

			img {
				height: 23px;
			}
		}
	}
}

.user-logged {
	font-size: 12px;
	margin-right: 5px;
	margin-top: 10px;

	&.user-logged-dark {
		color: #fff;
	}
}

select {
	height: 20px;
	outline: none;
	border: 1px solid #e0e2e4;
	border-radius: 4px;
	background: #fff;
	margin-bottom: 20px;
}

.button-theme {
	display: flex;
	align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
  display: none;
	.button-light {
		background: #fff;
		border: 1px solid #46484e;
		color: #46484e;
	}

	.button-dark {
		background: #1c1d21;
		border: 1px solid #1c1d21;
	}

	button {
		color: #fff;
		outline: none;
		cursor: pointer;
		border-radius: 4px;
		padding: 6px 12px;
		margin-left: 10px;
		border: none;
		font-size: 14px;
		transition: 0.3s;
		vertical-align: middle;

		&.button-github {
			height: 30px;
			background: none;
			padding: 0;
			margin-left: 20px;

			img {
				height: 30px;
			}
		}

		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}

		@media only screen and (max-width: 768px) {
			padding: 3px 6px;
			font-size: 13px;
		}
	}
}

.version-container {
	padding-top: 20px;
	text-align: right;
	font-size: 14px;
	color: grey;
}

</style>
