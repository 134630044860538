
// SDH_SERVER_TEST_ROW

const MONGO_API_SERVER_HOST = location.protocol + '//' + location.hostname + ':8085'
// const MONGO_API_SERVER_HOST = 'http://hustay.asuscomm.com:18085'
// const MONGO_API_SERVER_HOST = 'http://ec2-52-79-240-26.ap-northeast-2.compute.amazonaws.com:8085'
// const MONGO_API_SERVER_HOST = 'http://ec2-15-164-100-102.ap-northeast-2.compute.amazonaws.com:8085'
// const MONGO_API_SERVER_HOST = 'http://chat.swing2app.co.kr:8085'
const USERS_PATH = 'users'
const ROOMS_PATH = 'chatRooms'
const MESSAGES_PATH = 'messages'
const MESSAGE_PATH = roomId => {
	return `${ROOMS_PATH}/${roomId}/${MESSAGES_PATH}`
}

const TIMESTAMP_FIELD = 'timestamp'
const LAST_UPDATED_FIELD = 'lastUpdated'
const TYPING_USERS_FIELD = 'typingUsers'
const MESSAGE_REACTIONS_FIELD = 'reactions'
const ROOM_USERS_FIELD = 'users'

export const getUser = async (userId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/users/one/${userId}`
  let paramMap = {}
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const getUserBySwingDbId = async (appId, userDbId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/users/user_info`
  let paramMap = { appId: appId, userDbId: userDbId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const addUser = async (data) => {
  const url = `${MONGO_API_SERVER_HOST}/api/users`
  let paramMap = {}
  if (data && data.username) { paramMap['username'] = data.username }
  if (data && data.appId) { paramMap['app_id'] = data.appId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'post', paramMap)
}

export const updateUser = async (userId, data) => {
  const url = `${MONGO_API_SERVER_HOST}/api/users/${userId}`
  let paramMap = {}
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'put', paramMap)
}

export const getRooms = async (appId, currentUserId, roomType, roomsPerPage, startRooms, initRoomId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/rooms/by_user`
  let paramMap = {}
  if (appId) { paramMap['appId'] = appId }
  if (currentUserId) { paramMap['currentUserId'] = currentUserId }
  if (roomsPerPage) { paramMap['roomsPerPage'] = roomsPerPage }
  if (startRooms) { paramMap['startRooms'] = startRooms }
  if (roomType) { paramMap['roomType'] = roomType }
  if (initRoomId) { paramMap['initRoomId'] = initRoomId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const getRoomInfo = async (roomId, currentUserId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/rooms/one/${roomId}`
  let paramMap = {}
  if (roomId) { paramMap['roomId'] = roomId }
  if (currentUserId) { paramMap['currentUserId'] = currentUserId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const addRoom = async data => {
  const url = `${MONGO_API_SERVER_HOST}/api/rooms`
  let paramMap = {}
  if (data && data.app_id) { paramMap['app_id'] = data.app_id }
  if (data && data.my_id) { paramMap['my_id'] = data.my_id }
  if (data && data.target_user_string_id) { paramMap['target_user_string_id'] = data.target_user_string_id }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'post', paramMap)
}

export const updateRoom = async (roomId, data) => {
  const url = `${MONGO_API_SERVER_HOST}/api/rooms/${roomId}`
  let paramMap = {}
  if (data) {
    if (data && data.app_id) { paramMap['app_id'] = data.app_id }
    if (data.userArr) { paramMap['userArr'] = data.userArr }
    if (data.lastUpdated) { paramMap['lastUpdated'] = data.lastUpdated }
  }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'put', paramMap)
}

export const deleteRoom = async (appId, roomId, myMongoDbId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/rooms/${roomId}`
  let paramMap = { myMongoDbId: myMongoDbId, appId: appId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'delete', paramMap)
}

export const addRoomUser = async (roomId, userId, appId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/rooms/add_user/${roomId}`
  // eslint-disable-next-line standard/object-curly-even-spacing
  let paramMap = { 'userId': userId, 'appId': appId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'put', paramMap)
}

export const addRoomUserListBySwingUserDbIdListStr = async (roomId, swingUserDbIdListStr, appId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/rooms/add_user_by_swing_db_id/${roomId}`
  // eslint-disable-next-line standard/object-curly-even-spacing
  let paramMap = { 'swingUserDbIdListStr': swingUserDbIdListStr, 'appId': appId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'put', paramMap)
}


export const removeRoomUser = async (roomId, userId, appId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/rooms/remove_user/${roomId}`
  // eslint-disable-next-line standard/object-curly-even-spacing
  let paramMap = { 'userId': userId, 'appId': appId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'put', paramMap)
}

export const updateRoomTypingUsers = async (roomId, currentUserId, action) => {
	if (action === 'add') {
    // eslint-disable-next-line no-return-await,no-mixed-spaces-and-tabs
	  return await addRoomUser(roomId, currentUserId)
  } else {
    // eslint-disable-next-line no-return-await
    return await removeRoomUser(roomId, currentUserId)
  }
}

export const getMessages = async (roomId, messagesPerPage, lastLoadedMessage, previousLastLoadedMessage, swingAppId, currentUserId, isLteYn
) => {
  const url = `${MONGO_API_SERVER_HOST}/api/messages/room/${roomId}`
  let paramMap = {}
  if (messagesPerPage) { paramMap['messagesPerPage'] = messagesPerPage }
  if (lastLoadedMessage) { paramMap['lastLoadedMessage'] = lastLoadedMessage }
  if (previousLastLoadedMessage) { paramMap['previousLastLoadedMessage'] = previousLastLoadedMessage }

  if (swingAppId) { paramMap['swingAppId'] = swingAppId }
  if (currentUserId) { paramMap['currentUserId'] = currentUserId }
  if (isLteYn) { paramMap['isLteYn'] = isLteYn }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}


export const getMessagesBySearch = async (roomId, messagesPerPage, msgKeyword
) => {
  const url = `${MONGO_API_SERVER_HOST}/api/messages/room_search/${roomId}`
  let paramMap = {}
  if (messagesPerPage) { paramMap['messagesPerPage'] = messagesPerPage }
  if (msgKeyword) { paramMap['msgKeyword'] = msgKeyword }

  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const getMessagesByTargetBeforAfter = async (roomId, messagesPerPage, targetLoadedMessage
) => {
  const url = `${MONGO_API_SERVER_HOST}/api/messages/room_target_befor_after/${roomId}`
  let paramMap = {}
  if (messagesPerPage) { paramMap['messagesPerPage'] = messagesPerPage }
  if (targetLoadedMessage) { paramMap['targetLoadedMessage'] = targetLoadedMessage }

  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const getMessagesByTargetScroll = async (roomId, messagesPerPage, targetLoadedMessage, listTypeCode
) => {
  const url = `${MONGO_API_SERVER_HOST}/api/messages/room_target_scroll/${roomId}`
  let paramMap = {}
  if (messagesPerPage) { paramMap['messagesPerPage'] = messagesPerPage }
  if (targetLoadedMessage) { paramMap['targetLoadedMessage'] = targetLoadedMessage }

  // befor, after
  if (listTypeCode) { paramMap['listTypeCode'] = listTypeCode }

  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const getMessagesByAfter = async (roomId, nowLoadedMessage
) => {
  const url = `${MONGO_API_SERVER_HOST}/api/messages/room_after/${roomId}`
  let paramMap = {}
  if (nowLoadedMessage) { paramMap['nowLoadedMessage'] = nowLoadedMessage }

  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const getMessage = async (roomId, messageId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/messages/${messageId}`
  let paramMap = {}
  if (roomId) { paramMap['roomId'] = roomId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

export const addMessage = (roomId, data, roomUsers) => {
  return new Promise(function (resolve, reject) {
    const url = `${MONGO_API_SERVER_HOST}/api/messages`
    let paramMap = {}
    if (data) {
      paramMap['room_id'] = roomId
      if (data.sender_id) { paramMap['sender_id'] = data.sender_id }
      if (data.content) { paramMap['content'] = data.content }
      if (data.replyMessage && data.replyMessage._id) { paramMap['reply_message_id'] = data.replyMessage._id }
      if (data.replyMessage && data.replyMessage.content) { paramMap['reply_message_content'] = data.replyMessage.content }
      if (data.replyMessage && data.replyMessage.sender_id) { paramMap['reply_message_sender_id'] = data.replyMessage.sender_id }
      if (data.replyMessage && data.replyMessage.files) { paramMap['reply_message_files'] = data.replyMessage.files }

      if (roomUsers) { paramMap['room_users'] = roomUsers }

      if (data.files) { paramMap['files'] = data.files }

      if (data.app_id) { paramMap['app_id'] = data.app_id }
      if (data.system) { paramMap['system'] = data.system }
    }
      window.appVm.config.globalProperties.$socket.emit('sendMessage', paramMap, (response) => {
      const msgId = response.msgResult.id
      resolve({ id: msgId })
    })

  // eslint-disable-next-line no-return-await
  // return await swingMongoApiFn(url, 'post', paramMap)
  })
}

export const updateMessage = async(roomId, messageId, data) => {
  const url = `${MONGO_API_SERVER_HOST}/api/messages/${messageId}`
  let paramMap = {}
  if (data) {
    paramMap['room_id'] = roomId

    for (const [key, value] of Object.entries(data)) {
      paramMap[key] = value
    }
  }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'put', paramMap)
}

export const deleteMessage = async(roomId, messageId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/messages/${messageId}`
  let paramMap = { room_id: roomId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'delete', paramMap)
}

export const listenMessages = async(
	roomId,
	lastLoadedMessage,
	previousLastLoadedMessage,
	callback
  , swingAppId, currentUserId, isLteYn
) => {
  console.log('xxxxxxxxxxxxxxxxxxxxxxx  listenMessages xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')

  const result = await getMessages(
    roomId,
    null,
    lastLoadedMessage ? lastLoadedMessage.timestamp : null,
    previousLastLoadedMessage ? previousLastLoadedMessage.timestamp : null
    , swingAppId
    , currentUserId
    , isLteYn
  )

  return callback(result)
}

const formatQueryDataObject = queryData => {
	return { ...queryData.data(), id: queryData.id }
}

const formatQueryDataArray = queryDataArray => {
	const formattedData = []

	queryDataArray.forEach(data => {
		formattedData.push(formatQueryDataObject(data))
	})
	return formattedData
}

export const initDataFn = async (appId, userDbId, initRoomId) => {
  const url = `${MONGO_API_SERVER_HOST}/api/main`
  let paramMap = { app_id: appId, user_db_id: userDbId, init_room_id: initRoomId }
  // eslint-disable-next-line no-return-await
  return await swingMongoApiFn(url, 'get', paramMap)
}

// eslint-disable-next-line camelcase
export const swingMongoApiFn = async (p_url, p_method, p_paramMap) => {
  let settings = {
    method: p_method,
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  // eslint-disable-next-line camelcase,eqeqeq
  if (p_method == 'get' || p_method == 'delete') {
    // eslint-disable-next-line camelcase
    if (p_paramMap) {
      let urlParamStr = ''
      for (const [key, value] of Object.entries(p_paramMap)) {
        let word = '&'
        // eslint-disable-next-line eqeqeq
        if (p_url.indexOf('?') == -1) { word = '?' }

        // eslint-disable-next-line camelcase
        p_url += word + encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
    }

    // eslint-disable-next-line eqeqeq,camelcase
  } else if (p_method == 'post' || p_method == 'put') {
    settings['body'] = JSON.stringify(p_paramMap)
  }

  const response = await fetch(p_url, settings)
  if (!response.ok) {
    // eslint-disable-next-line no-template-curly-in-string
    const message = 'An error has occured: ${response.status}'
    // throw new Error(message)
  } else {
    const data = await response.json()
    return data
  }
}
