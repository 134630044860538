import axios from 'axios'

// SDH__SERVER_TEST_ROW

// const SWING_API_SERVER_HOST = 'http://localhost:8803'
// const SWING_API_SERVER_HOST = 'http://hustay.asuscomm.com:8803'
// const SWING_API_SERVER_HOST = 'http://ec2-52-79-240-26.ap-northeast-2.compute.amazonaws.com:8080'
const SWING_API_SERVER_HOST = 'https://www.swing2app.co.kr'

export const fileUploadS3Fn = async (fileInput, type, messageId, roomId, 	callback, error, success) => {
  const params = new URLSearchParams(window.location.search)

  const appId = params.get('app_id')
  const userDbId = params.get('user_db_id')

  if (fileInput) {
    let paramFileName = fileInput.name
    if (paramFileName.indexOf('.') === -1) {
      paramFileName += `.${fileInput.extension || fileInput.type}`
    }

    const urlResult = await makeFileUploadUrl(appId, paramFileName, type, 'vue_chat_attach', 'public', fileInput.size, 'Y', userDbId)

    const uploadUrlInfo = urlResult.data
    const storageUrl = uploadUrlInfo.resultMap.uploadUrl
    const fileName = uploadUrlInfo.resultMap.fileName
    const path = uploadUrlInfo.resultMap.path
    const acl = uploadUrlInfo.resultMap.acl
    const policy = uploadUrlInfo.resultMap.policy
    const signature = uploadUrlInfo.resultMap.signature
    const credential = uploadUrlInfo.resultMap.credential
    const snedDate = uploadUrlInfo.resultMap.snedDate

    let form = new FormData()

    form.append('key', path + fileName)
    form.append('acl', acl)
    form.append('policy', policy)
    // form.append('Content-Type', type)
    form.append('X-Amz-Signature', signature)
    form.append('X-Amz-Credential', credential)
    form.append('X-Amz-Date', snedDate + 'T000000Z')
    form.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256')
    form.append('file', fileInput.blob)

    // eslint-disable-next-line no-return-await
    const s3Result = await axios.post(storageUrl, form, {
      onUploadProgress: (progressEvent) => {
        let percentage = (progressEvent.loaded * 100) / progressEvent.total
        let percentCompleted = Math.round(percentage)
        callback(percentCompleted)
      },
      header: { 'Content-Type': 'multipart/form-data' }
      // eslint-disable-next-line standard/object-curly-even-spacing
    }).catch(err => {
      console.log(err)
      error(err)
    })
    success({ url: uploadUrlInfo.resultMap.fileUrl, swingFileId: uploadUrlInfo.resultMap.fileId })
    return uploadUrlInfo.resultMap
  }
}

export const makeFileUploadUrl = async (appid, fileName, contentType, atcKey, isPrivate, fileSize, isRename, userDbId) => {
  const url = `${SWING_API_SERVER_HOST}/swapi/create_file_upload_info_by_aws`
  let paramMap = {}

  if (appid) { paramMap['app_id'] = appid }
  if (fileName) { paramMap['file_name'] = fileName }
  if (contentType) { paramMap['content_type'] = contentType }
  if (atcKey) { paramMap['atc_key'] = atcKey }
  if (isPrivate) { paramMap['is_private'] = isPrivate }
  if (fileSize) { paramMap['file_size'] = fileSize }
  if (isRename) { paramMap['is_rename_yn'] = isRename }
  if (userDbId) { paramMap['temp_user_db_id'] = userDbId }
  // eslint-disable-next-line no-return-await
  return await swingApiFn(url, 'get', paramMap)
}

export const updateStorageDeltYn = async (appid, userDbId, fileId, deltYn) => {
  const url = `${SWING_API_SERVER_HOST}/swapi/update_storage_delt_yn`
  let paramMap = {}

  if (appid) { paramMap['app_id'] = appid }
  if (userDbId) { paramMap['user_db_id'] = userDbId }
  if (fileId) { paramMap['file_id'] = fileId }
  if (deltYn) { paramMap['delt_yn'] = deltYn }
  // eslint-disable-next-line no-return-await
  return await swingApiFn(url, 'get', paramMap)
}

// eslint-disable-next-line camelcase
export const swingApiFn2 = async (p_url, p_method, p_paramMap) => {
  let settings = {
    method: p_method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  // eslint-disable-next-line camelcase,eqeqeq
  if (p_method == 'get' || p_method == 'delete') {
    // eslint-disable-next-line camelcase
    if (p_paramMap) {
      let urlParamStr = ''
      for (const [key, value] of Object.entries(p_paramMap)) {
        let word = '&'
        // eslint-disable-next-line eqeqeq
        if (p_url.indexOf('?') == -1) { word = '?' }

        // eslint-disable-next-line camelcase
        p_url += word + encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
    }

    // eslint-disable-next-line eqeqeq,camelcase
  } else if (p_method == 'post' || p_method == 'put') {
    settings['body'] = JSON.stringify(p_paramMap)
  }

  const response = await fetch(p_url, settings)
  if (!response.ok) {
    // eslint-disable-next-line no-template-curly-in-string
    const message = 'An error has occured: ${response.status}'
    throw new Error(message)
  } else {
    const data = await response.json()
    return data
  }
  // eslint-disable-next-line no-unreachable
}

// eslint-disable-next-line camelcase
export const swingApiFn = async (p_url, p_method, p_paramMap) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  // eslint-disable-next-line camelcase,eqeqeq
  if (p_method == 'get' || p_method == 'delete') {
    // eslint-disable-next-line camelcase
    if (p_paramMap) {
      let urlParamStr = ''
      for (const [key, value] of Object.entries(p_paramMap)) {
        let word = '&'
        // eslint-disable-next-line eqeqeq
        if (p_url.indexOf('?') == -1) { word = '?' }

        // eslint-disable-next-line camelcase
        p_url += word + encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
    }

    // eslint-disable-next-line eqeqeq,camelcase
  }
  // eslint-disable-next-line no-return-await
  return await axios.get(p_url, p_paramMap, { headers })
    .catch(err => {
    console.log(err)
  })
}
