<template>
	<div class="window-container" :class="{ 'window-mobile': isDevice }">
<vue-advanced-chat
      ref="chatWindow"
			:height="viewHeightVal"
			:theme="theme"
			:styles="JSON.stringify(styles)"
			:current-user-id="currentUserId"
			:room-id="roomId"
			:rooms="JSON.stringify(loadedRooms)"
      :load-first-room="loadFirstRoomCasted"
			:loading-rooms="loadingRooms"
      :messages="JSON.stringify(messages)"
			:messages-loaded="messagesLoaded"
			:rooms-loaded="roomsLoaded"
      :room-actions="JSON.stringify(roomActions)"
      :menu-actions="JSON.stringify(menuActions)"
      :message-selection-actions="JSON.stringify(messageSelectionActions)"
			:room-message="roomMessage"
      :templates-text="JSON.stringify(templatesText)"
      :message-actions="JSON.stringify(messageActions)"
      :show-reaction-emojis="false"
      :username-options="JSON.stringify({minUsers: 2, currentUser: false})"
      :show-add-room="false"
      :text-messages="JSON.stringify(textMessages)"
      :single-room="isRoomListOpen"
      :auto-scroll="JSON.stringify(autoScroll)"
      :show-audio="showAudio"
      :text-formatting="JSON.stringify({disabled: false, italic: '_', bold: '*', strike: '~', underline: '°', multilineCode: '```', inlineCode: ''})"
			@fetch-more-rooms="fetchMoreRooms"
      @fetch-messages="fetchMessages($event.detail[0])"
			@send-message="sendMessage($event.detail[0])"
			@edit-message="editMessage($event.detail[0])"
			@delete-message="deleteMessage($event.detail[0])"
			@open-file="openFileRoot($event.detail[0])"
			@add-room="addRoom($event.detail[0])"
			@room-action-handler="menuActionHandler($event.detail[0])"
			@menu-action-handler="menuActionHandler($event.detail[0])"
      @message-action-handler="messageActionHandler($event.detail[0])"
			@message-selection-action-handler="messageSelectionActionHandler($event.detail[0])"
			@send-message-reaction="sendMessageReaction($event.detail[0])"
      @typing-message="typingMessage($event.detail[0])"
		>
			<!-- <template #emoji-picker="{ emojiOpened, addEmoji }">
				<button @click="addEmoji({ unicode: '😁' })">
					{{ emojiOpened }}
				</button>
			</template> -->

<!--    <div slot="room-header">-->
<!--      채팅방 커스텀-->
<!--    </div>-->

<!--    <div v-for="message in messages" :slot="'message_' + message._id">-->
<!--      <div v-if="message.system">-->
<!--        System message: {{ message.content }}-->
<!--      </div>-->
<!--      <div v-else>-->
<!--        <img />-->
<!--        커스텀 메시지: {{ message.content }}-->
<!--      </div>-->
<!--    </div>-->
		</vue-advanced-chat>
	</div>
</template>

<script>
import { parseTimestamp, formatTimestamp } from '@/utils/dates'

import * as mongoService from '@/database/mongo'
import * as fileuploadS3Service from '@/database/fileupload'
import {
  Timestamp
} from 'firebase/firestore'

import { register } from 'vue-advanced-chat'

import { getI18NFn } from '@/utils/string'

// window.appVm.$root.$toast.info('공지사항!!!!!!!!!!!!!!', {duration:5000});

// import { register } from './../../dist/vue-advanced-chat.es.js'
// import { register } from './../../src/lib/index.js'
register()

export default {
	props: {
		currentUserId: { type: String, required: true },
		theme: { type: String, required: true },
		isDevice: { type: Boolean, required: true }
	},

	data() {

    let messageActionArr = [
      {
        name: 'replyMessage',
        title: getI18NFn(this.langCode, 'm016')
      },
      {
        name: 'editMessage',
        title: getI18NFn(this.langCode, 'm017'),
        onlyMe: true
      },
      {
        name: 'deleteMessage',
        title: getI18NFn(this.langCode, 'm018'),
        onlyMe: true
      },
      // {
      //   name: 'selectMessages',
      //   title: 'Select',
      //   onlyMe: true
      // },
      {
        name: 'reportMessage',
        title: getI18NFn(this.langCode, 'm015')
      }
    ];
    if(this.blockUserYn=='Y'){
      messageActionArr.push({
        name: 'blockUserMessage',
        title: getI18NFn(this.langCode, 'm030')
      });
    }
		return {
			roomsPerPage: this.roomPagingRange,
			rooms: [],
			roomId: '',
      loadFirstRoomCasted: false,
			startRooms: null,
			endRooms: null,
			roomsLoaded: (this.roomPagingRange === 'N'),
			loadingRooms: true,
      // eslint-disable-next-line vue/no-unused-properties
			allUsers: [],
      allUserMap: {},
			loadingLastMessageByRoom: 0,
			roomsLoadedCount: false,
			selectedRoom: null,
      selectedRoomOb: null,
      loadFirstRoom: false,
			messagesPerPage: 20,
			messages: [],
			messagesLoaded: false,
			roomMessage: '',
			lastLoadedMessage: null,
			previousLastLoadedMessage: null,
			roomsListeners: [],
			listeners: [],
      // eslint-disable-next-line vue/no-unused-properties
			typingMessageCache: '',
			disableForm: false,
			addNewRoom: null,
			addRoomUsername: '',
			inviteRoomId: null,
			invitedUsername: '',
			removeRoomId: null,
			removeUserId: '',
			removeUsers: [],
			roomActions: [
				// { name: 'inviteUser', title: 'Invite User' },
				// { name: 'removeUser', title: 'Remove User' },
				// { name: 'deleteRoom', title: 'Delete Room' }
			],
			menuActions: [
				// { name: 'inviteUser', title: 'Invite User' },
				// { name: 'removeUser', title: 'Remove User' },
				// { name: 'deleteRoom', title: 'Delete Room' }
			],
			messageSelectionActions: [{ name: 'deleteMessages', title: 'Delete' }],
      // eslint-disable-next-line vue/no-unused-properties
			styles: { container: { borderRadius: '4px' } },
			templatesText: [
				{
					tag: getI18NFn(this.langCode, 'm019'),
					text: getI18NFn(this.langCode, 'm020')
				},
				{
					tag: getI18NFn(this.langCode, 'm021'),
					text: getI18NFn(this.langCode, 'm022')
				},
				{
					tag: getI18NFn(this.langCode, 'm023'),
					text: getI18NFn(this.langCode, 'm024')
				}
			],
      messageActions: messageActionArr,
			// ,dbRequestCount: 0
      textMessages: {
        // eslint-disable-next-line vue/no-unused-properties
        ROOMS_EMPTY: getI18NFn(this.langCode, 'm002'),
        // eslint-disable-next-line vue/no-unused-properties
        ROOM_EMPTY: getI18NFn(this.langCode, 'm003'),
        // eslint-disable-next-line vue/no-unused-properties
        NEW_MESSAGES: getI18NFn(this.langCode, 'm004'),
        // eslint-disable-next-line vue/no-unused-properties
        MESSAGE_DELETED: getI18NFn(this.langCode, 'm005'),
        // eslint-disable-next-line vue/no-unused-properties
        MESSAGES_EMPTY: getI18NFn(this.langCode, 'm006'),
        // eslint-disable-next-line vue/no-unused-properties
        CONVERSATION_STARTED: getI18NFn(this.langCode, 'm007'),
        // eslint-disable-next-line vue/no-unused-properties
        TYPE_MESSAGE: getI18NFn(this.langCode, 'm008'),
        // eslint-disable-next-line vue/no-unused-properties
        SEARCH: getI18NFn(this.langCode, 'm009'),
        // eslint-disable-next-line vue/no-unused-properties
        LAST_SEEN: getI18NFn(this.langCode, 'm010'),
        // eslint-disable-next-line vue/no-unused-properties
        IS_TYPING: getI18NFn(this.langCode, 'm011')
      },
      isRoomListOpen: false,
      // eslint-disable-next-line vue/no-unused-properties
      autoScroll: { send: { new: true, newAfterScrollUp: true }, receive: { new: true, newAfterScrollUp: false } },
      viewHeightVal: (window.innerWidth < 900) ? '100vh' : 'calc(100vh - 50px)',
      changeHeightParamVal: null,
      // eslint-disable-next-line vue/no-unused-properties
      ogHeightVal: null,
      // eslint-disable-next-line vue/no-unused-properties
      mediaPreviewEnabledVal: true,
      showAudio: false,
      isSearchYn:'N'
		}
	},

	computed: {
		loadedRooms() {
			return this.rooms.slice(0, this.roomsLoadedCount)
		},
    // eslint-disable-next-line vue/no-unused-properties
		screenHeight() {
      return '100vh'
			// return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh - 80px)'
		}
	},
  created() {
    const thisOb = this
    // eslint-disable-next-line no-mixed-spaces-and-tabs
	  const $socket = thisOb.$socket

    if (thisOb.initRoomId) {
      thisOb.loadFirstRoomCasted = true
      thisOb.roomId = thisOb.initRoomId
      thisOb.selectedRoom = thisOb.initRoomId
      thisOb.loadFirstRoom = true
    }
    // ADMIN_CHAT,BOARD_CHAT,USER_CHAT

    // eslint-disable-next-line eqeqeq
    if (
      thisOb.initRoomId
      // thisOb.getSelectedRoomTypeFn() == 'BOARD_CHAT' || thisOb.getSelectedRoomTypeFn() == 'ADMIN_CHAT' || thisOb.getSelectedRoomTypeFn() == 'USER_CHAT'
    ) {
      thisOb.isRoomListOpen = true
    }

    // eslint-disable-next-line camelcase
    thisOb.sendMessageFn = function (p_val, senderUserId, system) {
      thisOb.sendMessage({ content: p_val, roomId: thisOb.selectedRoom, senderUserId: senderUserId, system: system })
    }
    window.chatthisOb = thisOb
    thisOb.$socket.emit('user_init', {
      user_db_id: thisOb.getMyMongoUserDbIdFn()
    })
    const params = new URLSearchParams(window.location.search)

    const appId = params.get('app_id')
    const userDbId = params.get('user_db_id')
    const isSearchYn = params.get('is_search_yn')
    // this.isSearchYn = isSearchYn
    this.isSearchYn = 'Y'
    const showAudioYn = params.get('show-audio-yn')
    if (showAudioYn === 'Y') {
      this.showAudio = true
    }
    // ============================================================================
    // $socket.on('connect', () => {
    //   console.log('socket-connect')
    //   window.appVm.$root.$toast.info('connect' +  $socket.connected)
    //
    //
    //   // eslint-disable-next-line eqeqeq
    //   if (thisOb.selectedRoom && thisOb.selectedRoom != '') {
    //     if (thisOb.messages && thisOb.messages.length > 0) {
    //       const nowLoadedMessage = thisOb.messages[thisOb.messages.length - 1]
    //       const nowLoadedMessageTimestamp = nowLoadedMessage ? nowLoadedMessage.createdAt : null
    //       mongoService
    //         .getMessagesByAfter(thisOb.selectedRoom, nowLoadedMessageTimestamp)
    //         .then(data => {
    //           if (data && data.cnt && data.cnt > 0) {
    //             setTimeout(() => (thisOb.roomId = ''), 0)
    //             setTimeout(() => (thisOb.roomId = thisOb.selectedRoom), 10)
    //             const foundRoom = thisOb.rooms.find(r => r.roomId === thisOb.selectedRoom)
    //             if (foundRoom) {
    //               foundRoom.typingUsers = []
    //             }
    //           }
    //         })
    //     }
    //   }
    //
    //   $socket.emit('add_client_by_user', {
    //     app_id: appId,
    //     user_db_id: userDbId,
    //     room_id: thisOb.selectedRoom
    //   })
    // })

    // ====================================================
    // ========================

    // $socket.on('reconnect', () => {
    //   console.log('socket-reconnect')
    //   window.appVm.$root.$toast.info('reconnect')
    // })
    // this.$socket.emit('add_client_by_user', {
    //   app_id: appId,
    //   user_db_id: userDbId
    // })
  },
  mounted: function () {
    this.addCss()

    const thisOb = this
    thisOb.fetchRooms()
    thisOb.messageSocketFn()
    // firebaseService.updateUserOnlineStatus(this.getMyMongoUserDbIdFn())

    thisOb.$refs.chatWindow.shadowRoot.querySelectorAll('#messages-list')[0].addEventListener('click', (e) => {
      if (e.target && e.target.className === 'vac-avatar') {
        const msgBox = e.target.closest('div.vac-message-wrapper')
        const msgId = msgBox.id
        console.log(`msg id : ${msgId}`)
        const megItem = thisOb.messages.find(message => message._id === msgId)

        let userInfoMap = null

        if (megItem) {
          let postMsgParamMap = {}

          postMsgParamMap['senderId'] = megItem.senderId
          postMsgParamMap['userName'] = megItem.username
          postMsgParamMap['userImg'] = megItem.avatar
          postMsgParamMap['roomId'] = megItem.roomId

          if (thisOb.selectedRoomOb && thisOb.selectedRoomOb.users) {
            userInfoMap = thisOb.selectedRoomOb.users.find(u => u._id === megItem.senderId)
          }
          if (userInfoMap) {
            postMsgParamMap['swingUserDbId'] = userInfoMap.userDbId
            postMsgParamMap['swingUserStringId'] = userInfoMap.userStringId
          }

          window.parent.postMessage({
            function: 'msg_profile_img_click',
            msgInfo: JSON.parse(JSON.stringify(postMsgParamMap))
          }, '*')
        }
      }
    })

    thisOb.$socket.on('update-typingMessage', function ({ roomId, appId, mongoDbId, typeCode }) {
      console.log('update-typingMessag')
      const foundRoom = thisOb.rooms.find(r => r.roomId === roomId)
      if (foundRoom) {
        let tempTypingUsers = foundRoom.typingUsers
        if (!tempTypingUsers) {
          tempTypingUsers = []
        }
        if (tempTypingUsers) {
          // eslint-disable-next-line eqeqeq
          if (typeCode == 'add') {
            // eslint-disable-next-line camelcase
            thisOb.rooms.forEach((lp_room) => {
              // eslint-disable-next-line camelcase
              let lp_tempTypingUsers = lp_room.typingUsers
              // eslint-disable-next-line camelcase
              if (!lp_tempTypingUsers) {
                // eslint-disable-next-line camelcase
                lp_tempTypingUsers = []
              }
              // eslint-disable-next-line camelcase
              const removeArr = lp_tempTypingUsers.filter((lp_mongoDbId) => lp_mongoDbId !== mongoDbId)
              lp_room.typingUsers = removeArr
            })

            foundRoom.typingUsers = [...foundRoom.typingUsers, mongoDbId]

            // eslint-disable-next-line eqeqeq
          } else if (typeCode == 'remove') {
            // eslint-disable-next-line camelcase
            const removeArr = tempTypingUsers.filter((lp_mongoDbId) => lp_mongoDbId !== mongoDbId)
            foundRoom.typingUsers = removeArr
          }
        }
      }
    })

    // eslint-disable-next-line eqeqeq
    if (thisOb.customFileUploadYn == 'Y') {
      setTimeout(() => {
        const eventName = 'click'
        const el = document.getElementById('vac-icon-paperclip').closest('div')
        // const el = document.querySelectorAll('.vac-box-footer .vac-svg-button')[0]
        const newListenerFn = function () {
          alert('custom file upload event!!')
        }

        const inputEl = document.querySelectorAll(".vac-box-footer input[type='file']")[0]
        let btnEl = document.createElement('div')
        btnEl.setAttribute('class', 'vac-svg-button')
        btnEl.setAttribute('id', 'fileUploadAskBtn')
        btnEl.innerHTML = el.innerHTML
        el.remove()
        inputEl.before(btnEl)

        btnEl.addEventListener(eventName, newListenerFn)
      }, 100)
    }

    window.addEventListener('message', function (event) {
      // eslint-disable-next-line eqeqeq
      if (event.data.function == 'removeRoomUser') {
        const mongoUserId = event.data.mongoUserId ? event.data.mongoUserId : thisOb.getMyMongoUserDbIdFn()

        thisOb.removeRoomId = thisOb.selectedRoom
        thisOb.removeUserId = mongoUserId

        thisOb.deleteRoomUser(thisOb.removeRoomId, mongoUserId)
          .then(() => {

          })
        // eslint-disable-next-line eqeqeq
      } else if (event.data.function == 'sendChatMessage') {
        const sendMessage = event.data.message
        thisOb.sendMessageFn(sendMessage)
        // eslint-disable-next-line eqeqeq
      } else if (event.data.function == 'setTemplatesText') {
        // eslint-disable-next-line camelcase
        const temp_templatesText = event.data.templatesText
        // eslint-disable-next-line camelcase
        if (temp_templatesText && Array.isArray(temp_templatesText)) {
          // eslint-disable-next-line camelcase
          thisOb.templatesText = temp_templatesText
        }
        // eslint-disable-next-line eqeqeq
      } else if (event.data.function == 'invite_user') {
        // eslint-disable-next-line camelcase
        const swingUserDbIdListStr = event.data.swingUserDbIdListStr
        // eslint-disable-next-line camelcase,eqeqeq
        if (swingUserDbIdListStr && swingUserDbIdListStr != '') {
          mongoService.addRoomUserListBySwingUserDbIdListStr(thisOb.roomId, swingUserDbIdListStr, thisOb.swingAppId)
            .then(() => {

            })
        }
        // eslint-disable-next-line eqeqeq
      } else if (event.data.function == 'resize_height') {
        const heightVal = event.data.keyboardHeightVal
        const messagesListBoxEl = thisOb.$refs.chatWindow.shadowRoot.querySelectorAll('#messages-list')[0]
        let val = messagesListBoxEl.scrollTop

        if (heightVal) {
          thisOb.viewHeightVal = `calc(100vh - ${heightVal})`

          thisOb.ogHeightVal = val
          thisOb.changeHeightParamVal = parseInt(heightVal)
          const setHeightVal = (val + thisOb.changeHeightParamVal)
          setTimeout(() => messagesListBoxEl.scrollTo({ top: setHeightVal }), 10)
          console.log(`if : ${val} ${setHeightVal}`)
        } else {
          thisOb.viewHeightVal = '100vh'

          if (thisOb.changeHeightParamVal) {
            const setHeightVal = (val - thisOb.changeHeightParamVal)
            setTimeout(() => messagesListBoxEl.scrollTo({ top: setHeightVal }), 10)

            console.log(`else : ${val} ${setHeightVal}`)
          }
          thisOb.ogHeightVal = null
          thisOb.changeHeightParamVal = null
        }
        // eslint-disable-next-line eqeqeq
      } else if (event.data.function == 'systemSendChatMessage') {
        const sendMessage = event.data.message
        thisOb.sendMessageFn(sendMessage, null, true)
      } else if (event.data.function === 'imgSendChatMessage') {
        const sendMessage = event.data.message
        const files = event.data.files
          // [{"name":"20220620_092430","size":106670,"type":"image/png","extension":"png","url":"https://ncdn2.swing2app.co.kr/public/chat_article_attach/7313677/20221715mb_up.jpg", "swingFileId":"7313677"}]
        if (!files || files.length === 0) {
          return
        }
        thisOb.sendMessage({ content: sendMessage, roomId: thisOb.selectedRoom, files: files })
      } else if (event.data.function === 'fileDownEvIntercepter') {
        const eventIntercepterYn = event.data.eventIntercepterYn
        window.chatthisOb.$refs.chatWindow.mediaPreviewEnabled = !(eventIntercepterYn === 'Y')
        thisOb.mediaPreviewEnabledVal = !(eventIntercepterYn === 'Y')
      }
    }, false)

    setTimeout(() => {
      const vinfoBoxEl = window.chatthisOb.$refs.chatWindow.shadowRoot.querySelectorAll('.vac-info-wrapper')[0]
      let searchOpenBtnEl = document.createElement('div')
      // searchOpenBtnEl.setAttribute('class', 'vac-svg-button')
      searchOpenBtnEl.setAttribute('id', 'fileUploadAskBtn')

      let iconEl = document.createElement('img')
      iconEl.src = require('@/assets/search_icon.svg')
      iconEl.style.width='20px'
      iconEl.style.padding='5px'
      searchOpenBtnEl.appendChild(iconEl)

      // searchOpenBtnEl.innerHTML = 'xxxxxxxx'
      searchOpenBtnEl.addEventListener('click', () => { window.toggleModalFn() })
      if(thisOb.isSearchYn==='Y'){
        vinfoBoxEl.after(searchOpenBtnEl)
      }
    }, 100)

    window.getMyMongoUserDbIdFn = thisOb.getMyMongoUserDbIdFn
    window.getAllUserMapFn = thisOb.getAllUserMapFn
    window.getSelectedRoomIdFn = thisOb.getSelectedRoomIdFn
  },

	methods: {

		resetRooms() {
			this.loadingRooms = true
			this.loadingLastMessageByRoom = 0
			this.roomsLoadedCount = 0
			this.rooms = []
			this.roomsLoaded = true
			this.startRooms = null
			this.endRooms = null
			this.roomsListeners.forEach(listener => listener())
			this.roomsListeners = []
      this.typingMessageCache = ''
			this.resetMessages()
		},

		resetMessages() {
			this.messages = []
			this.messagesLoaded = false
			this.lastLoadedMessage = null
			this.previousLastLoadedMessage = null
      this.typingMessageCache = ''
			// this.listeners.forEach(listener => listener())
			// this.listeners = []
		},

		fetchRooms() {
			this.resetRooms()
			this.fetchMoreRooms()
      this.typingMessageCache = ''
		},
    // eslint-disable-next-line vue/no-unused-properties
    messageSocketFn() {
      const me = this
      me.$socket.on('result-message', function (resultData) {
        console.log('result-message')

        resultData.forEach(message => {
          if (me.selectedRoomOb) {
            const formattedMessage = me.formatMessage(me.selectedRoomOb, message);

            if(formattedMessage.isBlockUserVal===true){ return true; }

            const messageIndex = me.messages.findIndex(
              m => m._id === message.id
            )

            if (messageIndex === -1) {
              me.messages = me.messages.concat([formattedMessage])
            } else {
              me.messages[messageIndex] = formattedMessage
              me.messages = [...me.messages]
            }

            me.markMessagesSeen(me.selectedRoomOb, message)
          }
        })
      })

      me.$socket.on('update-message', function (resultData) {
        console.log('update-message')

        resultData.forEach(message => {
          if (me.selectedRoom && me.selectedRoom === message.roomId) {
            const formattedMessage = me.formatMessage(me.selectedRoomOb, message)

            if(formattedMessage.isBlockUserVal===true){ return true; }

            const messageIndex = me.messages.findIndex(
              m => m._id === message.id
            )

            if (messageIndex === -1) {
              me.messages = me.messages.concat([formattedMessage])
            } else {
              me.messages[messageIndex] = formattedMessage
              me.messages = [...me.messages]
            }
          }

          // 룸리스트 라스트도
          const roomIndex = me.rooms.findIndex(
            r => message.roomId === r.roomId
          )
          if (roomIndex > -1 && (me.rooms[roomIndex].lastMessage.id || me.rooms[roomIndex].lastMessage._id) === message.id) {
            const lastMessage = me.formatLastMessage(message, me.rooms[roomIndex])
            me.rooms[roomIndex].lastMessage = lastMessage
            me.rooms = [...me.rooms]
          }
        })
      })

      me.$socket.on('add-rooms', function (resultData) {
        console.log('add-rooms  ')

        // if (me.initRoomId) {
        //   return
        // }
        me.formatRoomsInfoFn(resultData)
          .then(formattedRooms => {
            const roomIndex = me.rooms.findIndex(
              r => formattedRooms[0].roomId === r.roomId
            )
            if (roomIndex > -1) {
              me.rooms[roomIndex] = formattedRooms[0]
              me.rooms = [...me.rooms]
            } else {
              // 새로 추가될때 현재 msg 리스트가 새로고침되서 추가안함.
              me.rooms = me.rooms.concat(formattedRooms)
            }

            me.startRooms = me.rooms[me.rooms.length - 1]
            //
            me.roomsLoadedCount = me.rooms.length
            //
            me.loadingRooms = false
            // me.roomsLoaded = false

            // formattedRooms.forEach(room => me.listenLastMessage(room))

            // ADMIN_CHAT,BOARD_CHAT,USER_CHAT
            // 이거때문에 방초기화되네?
            // eslint-disable-next-line eqeqeq
            if ((me.getSelectedRoomTypeFn() != 'BOARD_CHAT' && me.getSelectedRoomTypeFn() != 'ADMIN_CHAT' && me.getSelectedRoomTypeFn() != 'USER_CHAT') && me.rooms.length == 1) {
              // me.selectedRoom = me.rooms[0].roomId
              // me.selectedRoomOb = me.rooms[0]
              me.fetchRooms()
            }
          })

        // me.addNewRoom = false
        // me.addRoomUsername = ''
        // me.fetchRooms()
      })
      me.$socket.on('lastMessage', function ({ message }) {
        console.log('lastMessage')
        // this.incrementDbCounter('Listen Last Room Message', messages.length)
        const roomIndex = me.rooms.findIndex(
          r => message.roomId === r.roomId
        )

        if (roomIndex > -1) {
          const lastMessage = me.formatLastMessage(message, me.rooms[roomIndex])
          me.rooms[roomIndex].lastMessage = lastMessage
          me.rooms = [...me.rooms]
        } else {
          me.reloadRooms()
        }
      })

      me.$socket.on('remove-room', function (roomId, roomData, removeUserIdByMongo, deleteRoom) {
        // console.log(roomId)
        console.log('remove-room  ' + me.myMongoId)

        // eslint-disable-next-line eqeqeq
        if (me.selectedRoom == roomId && (me.myMongoId == removeUserIdByMongo || deleteRoom == 'deleteRoom')) {
          me.loadFirstRoomCasted = false
          me.fetchRooms()
          // eslint-disable-next-line eqeqeq
        } else if (me.selectedRoom == roomId) {
          const roomIndex = me.rooms.findIndex(
            r => roomData.id === r.roomId
          )

          const userInfoArr = me.rooms[roomIndex].users.filter((userInfo) => userInfo._id !== removeUserIdByMongo)
          const roomContacts = userInfoArr.filter(
            user => user._id !== me.getMyMongoUserDbIdFn()
          )

          me.rooms[roomIndex].users = userInfoArr
          me.rooms[roomIndex].roomName = (me.rooms[roomIndex].roomName)
            ? me.rooms[roomIndex].roomName : (roomContacts.map(user => user.username).join(', ') || 'Myself')

          me.rooms = [...me.rooms]
          me.parentGroupUserCnt(me.rooms[roomIndex].users)
        } else {
          const foundRooms = me.rooms.filter((room) => room.roomId !== roomId)

          me.rooms = foundRooms
          me.startRooms = me.rooms[me.rooms.length - 1]
          me.roomsLoadedCount = me.rooms.length
          // me.roomsLoaded = false
        }
      })
    },
    // eslint-disable-next-line vue/no-unused-properties
    async formatRoomsInfoFn(data) {
      // const roomUserIds = []
      // data.forEach(room => {
      //   room.users.forEach(userId => {
      //     const foundUser = this.allUsers.find(user => user?._id === userId)
      //     if (!foundUser && roomUserIds.indexOf(userId) === -1) {
      //       roomUserIds.push(userId)
      //     }
      //   })
      // })

      // this.incrementDbCounter('Fetch Room Users', roomUserIds.length)
      // const rawUsers = []
      // roomUserIds.forEach(userId => {
      //   const promise = mongoService.getUser(userId)
      //   rawUsers.push(promise)
      // })

      // this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))]

      const roomList = {}
      data.forEach(room => {
        roomList[room.id] = { ...room }

        // eslint-disable-next-line camelcase
        room.users.forEach((lp_userInfo) => {
          // eslint-disable-next-line camelcase
          this.allUserMap[lp_userInfo._id] = lp_userInfo
        //   const foundUser = this.allUsers.find(user => user?._id === userId)
        //   if (foundUser) roomList[room.id].users.push(foundUser)
        })
      })
      const formattedRooms = []

      Object.keys(roomList).forEach(key => {
        const room = roomList[key]

        const roomContacts = room.users.filter(
          user => user._id !== this.getMyMongoUserDbIdFn()
        )

        room.roomName = (room.roomName) ? room.roomName
          : (roomContacts.map(user => user.username).join(', ') || 'Myself')

        // eslint-disable-next-line eqeqeq
        if (this.selectedRoom == key) {
          this.parentGroupUserCnt(room.users)
        }

        let userImgUrl = (roomContacts && roomContacts[0] && roomContacts[0].avatar) ? roomContacts[0].avatar : null
        // eslint-disable-next-line eqeqeq
        if (userImgUrl && (userImgUrl.indexOf('http://') == -1 && userImgUrl.indexOf('https://') == -1)) {
          userImgUrl = 'https://ncdn2.swing2app.co.kr' + userImgUrl
        }

        const roomAvatar =
          roomContacts.length === 1 && userImgUrl
            ? userImgUrl
            : ((this.appInfoData && this.appInfoData.appIconUrl) ? this.appInfoData.appIconUrl : require('@/assets/user.png'))

        const dateTimestampOb = Timestamp.fromDate(new Date(room.lastUpdated))

        let lastMessage = {
            content: '',
            timestamp: formatTimestamp(
              new Date(dateTimestampOb.seconds),
              dateTimestampOb
            )
          }
        if (room.lastMessage) {
          lastMessage = this.formatLastMessage(room.lastMessage, room)
        }

        formattedRooms.push({
          ...room,
          roomId: key,
          avatar: roomAvatar,
          index: dateTimestampOb.seconds,
          // index: new Date(room.lastUpdated).getTime(),
          // lastMessage: {
          //   content: '',
          //   timestamp: formatTimestamp(
          //     new Date(dateTimestampOb.seconds),
          //     dateTimestampOb
          //   )
          //   // timestamp: new Date(room.lastUpdated)
          // }
          lastMessage: lastMessage
        })
      })
      return formattedRooms
    },

		async fetchMoreRooms() {
			if (this.endRooms && !this.startRooms) {
				this.roomsLoaded = true
				return
			}

			// const query = firestoreService.roomsQuery(
			// 	this.getMyMongoUserDbIdFn(),
			// 	this.roomsPerPage,
			// 	this.startRooms
			// )

      let roomUserId = this.getMyMongoUserDbIdFn()
      if (this.gohostModeYn === 'Y') {
        roomUserId = undefined
      }

      const startRoomLastUpdated = this.startRooms ? this.startRooms.lastUpdated : null
			// const { data, docs } = await firestoreService.getRooms(query)
      const data = await mongoService.getRooms(this.swingAppId, roomUserId, this.getSelectedRoomTypeFn(), this.roomsPerPage, startRoomLastUpdated, this.initRoomId)
			// this.incrementDbCounter('Fetch Rooms', data.length)

			this.roomsLoaded = this.roomsPerPage === 0 || data.length === 0 || data.length < this.roomsPerPage

			if (this.startRooms) this.endRooms = this.startRooms
			this.startRooms = data[data.length - 1]

			const formattedRooms = await this.formatRoomsInfoFn(data)

			this.rooms = this.rooms.concat(formattedRooms)

      if (!this.selectedRoom && this.rooms.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        this.selectedRoom = this.rooms[0].roomId
      }

			if (!this.rooms.length) {
				this.loadingRooms = false
				this.roomsLoadedCount = 0
			} else {
        this.loadingRooms = false
        this.roomsLoadedCount = this.rooms.length
      }

			this.listenUsersOnlineStatus(formattedRooms)

      this.$socket.emit('listenRooms', {
        roomsPerPage: this.roomsPerPage ? this.roomsPerPage : null,
        startRooms: this.startRooms ? this.startRooms.lastUpdated : null
      })
      // this.listenRooms(query)
			// setTimeout(() => console.log('TOTAL', this.dbRequestCount), 2000)
		},

    // eslint-disable-next-line vue/no-unused-properties
    async reloadRooms() {
      this.startRooms = null
      this.endRooms = null

      const startRoomLastUpdated = this.startRooms ? this.startRooms.lastUpdated : null
      // const { data, docs } = await firestoreService.getRooms(query)
      let roomUserId = this.getMyMongoUserDbIdFn()
      if (this.gohostModeYn === 'Y') {
        roomUserId = undefined
      }

        const data = await mongoService.getRooms(this.swingAppId, roomUserId, this.getSelectedRoomTypeFn(), this.roomsPerPage, startRoomLastUpdated, this.initRoomId)
      // this.incrementDbCounter('Fetch Rooms', data.length)

      this.roomsLoaded = this.roomsPerPage === 0 || data.length === 0 || data.length < this.roomsPerPage

      if (this.startRooms) this.endRooms = this.startRooms
      this.startRooms = data[data.length - 1]

      const formattedRooms = await this.formatRoomsInfoFn(data)

      this.rooms = formattedRooms

      this.listenUsersOnlineStatus(formattedRooms)

      this.$socket.emit('listenRooms', {
        roomsPerPage: this.roomsPerPage ? this.roomsPerPage : null,
        startRooms: this.startRooms ? this.startRooms.lastUpdated : null
      })
      // this.listenRooms(query)
      // setTimeout(() => console.log('TOTAL', this.dbRequestCount), 2000)
    },

    // eslint-disable-next-line vue/no-unused-properties
		listenLastMessage(room) {
			// const listener = firestoreService.listenLastMessage(
			// 	room.roomId,
			// 	messages => {
			// 		this.incrementDbCounter('Listen Last Room Message', messages.length)
			// 		messages.forEach(message => {
			// 			const lastMessage = this.formatLastMessage(message, room)
			// 			const roomIndex = this.rooms.findIndex(
			// 				r => room.roomId === r.roomId
			// 			)
			// 			this.rooms[roomIndex].lastMessage = lastMessage
			// 			this.rooms = [...this.rooms]
			// 		})
			// 		if (this.loadingLastMessageByRoom < this.rooms.length) {
			// 			this.loadingLastMessageByRoom++
      //
			// 			if (this.loadingLastMessageByRoom === this.rooms.length) {
			// 				this.loadingRooms = false
			// 				this.roomsLoadedCount = this.rooms.length
			// 			}
			// 		}
			// 	}
			// )
      //
			// this.roomsListeners.push(listener)
		},

    // eslint-disable-next-line camelcase
		formatLastMessage(message, room) {
			if (!message.timestamp) return

      if (!message.sender_id && message.senderId) {
        message.sender_id = message.senderId
      }

			let content = message.content
			if (message.files?.length) {
				const file = message.files[0]
				content = `${file.name}.${file.extension || file.type}`
			}
      const username =
          message.sender_id !== this.getMyMongoUserDbIdFn()
            // ? (room && room.users && room.users.find(user => message.sender_id === user._id))?.username
            ? (this.allUserMap && this.allUserMap[message.sender_id])?.username
            : ''

      const dateTimestampObByNow = Timestamp.fromDate(new Date())
      const dateTimestampOb = Timestamp.fromDate(new Date(message.timestamp))

      const dateStrByYYMMDDNow = parseTimestamp(dateTimestampObByNow, 'DD/MM/YY')
      const dateStrByYYMMDD = parseTimestamp(dateTimestampOb, 'DD/MM/YY')
      const dateStrByTime = parseTimestamp(dateTimestampOb, 'HH:mm')

      let seenVal = false

      if (message.roomUsers && message.seens && message.roomUsers.length < 100) {
        // const userInfoArr = room.users.filter((userInfo) => userInfo._id !== message.sender_id)
        const userInfoArr = message.roomUsers.filter((mongoUserId) => mongoUserId !== message.sender_id)
        let userMongoDbIdArr = []
        userInfoArr.forEach(mongoUserId => {
          userMongoDbIdArr.push(mongoUserId)
        })

        let notReadUserArr = message.roomUsers.filter(x => !message.seens.includes(x))
        notReadUserArr = notReadUserArr.filter(x => userMongoDbIdArr.includes(x))
        // eslint-disable-next-line eqeqeq
        if (notReadUserArr && notReadUserArr.length == 0) {
          seenVal = true
        }
        // eslint-disable-next-line eqeqeq
      } else if (message.roomUsers == null || message.roomUsers == undefined || message.roomUsers.length == 0) {
        seenVal = true
      }

      if (this.selectedRoomOb && this.selectedRoomOb.id === message.roomId && message.sender_id !== this.getMyMongoUserDbIdFn()) {
        seenVal = true
      }

      let distributed = false
      if (message.sender_id !== this.getMyMongoUserDbIdFn()) {
        distributed = true
      }

			return {
				...message,
				...{
					content,
					senderId: message.sender_id,
					timestamp: ((dateStrByYYMMDDNow !== dateStrByYYMMDD) ? (dateStrByYYMMDD + ' ') : '') + dateStrByTime,
					username: username,
					distributed: false,
					seen: false,
          saved: false,
					new:
						message.sender_id !== this.getMyMongoUserDbIdFn() && !seenVal,
					lastMessage: { ...message.lastMessage, senderId: this.getMyMongoUserDbIdFn() }
				}
			}
		},

		fetchMessages({ room, options = {} }) {
			// this.$emit('show-demo-options', false)

      // eslint-disable-next-line no-mixed-spaces-and-tabs
      if (this.gohostModeYn === 'Y') {
        // eslint-disable-next-line vue/no-mutating-props
        // window.chatAppThisOb.setCurrentUserId(room.users[1]._id)
      }

      this.loadFirstRoom = false

			if (options.reset) {
				this.resetMessages()
        // 두번 호출되서
				// this.roomId = room.roomId
			}

			if (this.previousLastLoadedMessage && !this.lastLoadedMessage) {
				this.messagesLoaded = true
				return
			}

			this.selectedRoom = room.roomId
      this.selectedRoomOb = room

      // eslint-disable-next-line eqeqeq
      if (this.messagesLoaded == false && this.messages.length == 0) {
        this.firstRoomGotoEventFn(room.roomId)

        const roomIndex = this.rooms.findIndex(
          // eslint-disable-next-line no-mixed-spaces-and-tabs
          					r => room.roomId === r.roomId
          // eslint-disable-next-line no-mixed-spaces-and-tabs
          				)
        if (
            this.gohostModeYn !== 'Y' &&
            (roomIndex > -1 && this.rooms[roomIndex] && this.rooms[roomIndex].lastMessage)
        ) {
          // this.rooms[roomIndex].lastMessage.seen = true
        }
      }

      const lastLoadedMessageTimestamp = this.lastLoadedMessage ? this.lastLoadedMessage.timestamp : null
      const previousLastLoadedMessageTimestamp = this.previousLastLoadedMessage ? this.previousLastLoadedMessage.timestamp : null

      if (lastLoadedMessageTimestamp == null) {
        this.parentGroupUserCnt(room.users)
      }

      let timeVal = 10
      if (this.userOsVal === 'ios') {
        timeVal = 500
      }

      mongoService
				.getMessages(room.roomId, this.messagesPerPage, lastLoadedMessageTimestamp, null, this.swingAppId, this.getMyMongoUserDbIdFn(), undefined)
				.then(data => {
          // this.$refs.chatWindow.shadowRoot.querySelectorAll('#messages-list')[0].style['overflow-y'] = 'hidden'
          // eslint-disable-next-line no-mixed-spaces-and-tabs
				  /// ///////////////////////////////////////
          setTimeout(() => {
          // this.$refs.chatWindow.shadowRoot.querySelectorAll('#messages-list')[0].style['overflow-y'] = 'auto'
					// this.incrementDbCounter('Fetch Room Messages', messages.length)
					if (this.selectedRoom !== room.roomId) return

          if (!data) { data = [] }

          if (data.length === 0 || data.length < this.messagesPerPage) {
						setTimeout(() => (this.messagesLoaded = true), 0)
					}

					if (options.reset) this.messages = []

          let tempArr = this.messages

					data.forEach(message => {
						const formattedMessage = this.formatMessage(room, message)

            if(formattedMessage.isBlockUserVal===true){ return true; }

            // this.messages.unshift(formattedMessage)
            tempArr.unshift(formattedMessage)
            this.markMessagesSeen(room, message)
					})

          this.messages = tempArr

					if (this.lastLoadedMessage) {
						this.previousLastLoadedMessage = this.lastLoadedMessage
					}
					this.lastLoadedMessage = data[data.length - 1]

          const params = new URLSearchParams(window.location.search)

          const appId = params.get('app_id')
          const userDbId = params.get('user_db_id')
          this.$socket.emit('listenMessages', {
            roomId: room.roomId,
            lastLoadedMessage: this.lastLoadedMessage ? this.lastLoadedMessage.timestamp : null,
            previousLastLoadedMessage: this.previousLastLoadedMessage ? this.previousLastLoadedMessage.timestamp : null,
            app_id: appId,
            user_db_id: userDbId
          })
					// this.listenMessages(room)
        }, timeVal)

      /// //////////////////////////
				})
		},

    // eslint-disable-next-line vue/no-unused-properties
		listenMessages(room) {
      // this.$socket.on('result-message', function (resultData) {
      //   console.log(resultData)
      // })

      const listener = mongoService.listenMessages(
				room.roomId,
				this.lastLoadedMessage,
				this.previousLastLoadedMessage,
				messages => {
					messages.forEach(message => {
						const formattedMessage = this.formatMessage(room, message)

            if(formattedMessage.isBlockUserVal===true){ return true; }

            const messageIndex = this.messages.findIndex(
							m => m._id === message.id
						)

						if (messageIndex === -1) {
							this.messages = this.messages.concat([formattedMessage])
						} else {
							this.messages[messageIndex] = formattedMessage
							this.messages = [...this.messages]
						}

						this.markMessagesSeen(room, message)
					})
				}
        , this.swingAppId, this.getMyMongoUserDbIdFn(), undefined
			)
			this.listeners.push(listener)
		},

    // eslint-disable-next-line vue/no-unused-properties
		markMessagesSeen(room, message) {
      // eslint-disable-next-line no-mixed-spaces-and-tabs
		  if (message.roomUsers && message.roomUsers.length >= 100) {
        // eslint-disable-next-line no-mixed-spaces-and-tabs
		    return false
      }

      // eslint-disable-next-line no-mixed-spaces-and-tabs
		  if (this.gohostModeYn === 'Y') {
        // eslint-disable-next-line no-mixed-spaces-and-tabs
		    return false
      }

			if (
				message.sender_id !== this.getMyMongoUserDbIdFn() &&
				(message.seens)
			) {
        const seenVal = message.seens.find(v => v === this.getMyMongoUserDbIdFn())
        if (!seenVal) {
          mongoService.updateMessage(room.roomId, message.id, {
            [`seen.${this.getMyMongoUserDbIdFn()}`]: new Date()
          })
        }
			}
		},

		formatMessage(room, message) {
      const dateTimestampOb = Timestamp.fromDate(new Date(message.timestamp))

      let seenVal = false
      // if (
      //   message.seens
      // ) {
      //   // eslint-disable-next-line eqeqeq
      //   const seenUsers = message.seens.filter(v => v != this.getMyMongoUserDbIdFn())
      //   if (seenUsers && seenUsers.length > 0) {
      //     seenVal = true
      //   }
      // }

      if (message.roomUsers && message.seens && message.roomUsers.length < 100) {
        // const userInfoArr = room.users.filter((userInfo) => userInfo._id !== message.sender_id)
        const userInfoArr = message.roomUsers.filter((mongoUserId) => mongoUserId !== message.sender_id)
        let userMongoDbIdArr = []
        userInfoArr.forEach(mongoUserId => {
          userMongoDbIdArr.push(mongoUserId)
        })

        let notReadUserArr = message.roomUsers.filter(x => !message.seens.includes(x))
        notReadUserArr = notReadUserArr.filter(x => userMongoDbIdArr.includes(x))
        // eslint-disable-next-line eqeqeq
        if (notReadUserArr && notReadUserArr.length == 0) {
          seenVal = true
        }
        // eslint-disable-next-line eqeqeq
      } else if (message.roomUsers == null || message.roomUsers == undefined || message.roomUsers.length == 0) {
        seenVal = true
      }

      let userImgUrl = (this.allUserMap && this.allUserMap[message.sender_id] && this.allUserMap[message.sender_id].avatar) ? this.allUserMap[message.sender_id].avatar : null
      // eslint-disable-next-line eqeqeq
      if (userImgUrl && (userImgUrl.indexOf('http://') == -1 && userImgUrl.indexOf('https://') == -1)) {
        userImgUrl = 'https://ncdn2.swing2app.co.kr' + userImgUrl
      }

      // eslint-disable-next-line eqeqeq
      if (message.sender_id == this.getMyMongoUserDbIdFn()) {
        userImgUrl = null
        // eslint-disable-next-line eqeqeq
      } else if (message.sender_id != this.getMyMongoUserDbIdFn() && !userImgUrl) {
        userImgUrl = require('@/assets/user.png')
      }

      let senderUserId = message.sender_id

      const senderUserInfoOb = (this.selectedRoomOb && this.selectedRoomOb.users) ? this.selectedRoomOb.users.find(u => u._id === senderUserId) : {}

      if (
        this.gohostModeYn === 'Y' && senderUserInfoOb && senderUserInfoOb.userDbId === room.senderUserDId
      ) {
        senderUserId = this.getMyMongoUserDbIdFn()
        userImgUrl = null
      }

      const isBlockUserVal = this.isBlockUserFn(message.roomId, senderUserId);

      if(isBlockUserVal==true){
        // message.system = true;
        // message.content = '';
        // message.deleted=true;
        // message.messageSelectionEnabled=true;
      }
      // message.content = isBlockUserVal + message.content;
      const formattedMessage = {
				...message,
				...{
					senderId: senderUserId,
					_id: message.id,
					seconds: dateTimestampOb.seconds,
					timestamp: parseTimestamp(dateTimestampOb, 'HH:mm'),
					date: parseTimestamp(dateTimestampOb, 'DD MMMM YYYY'), // date format
					// username: room.users.find(user => message.sender_id === user._id)?.username,
          username: (this.allUserMap && this.allUserMap[message.sender_id])?.username,
					// avatar: senderUser ? senderUser.avatar : null,
					distributed: true,
					lastMessage: { ...message.lastMessage, senderId: message.sender_id },
          seen: seenVal,
          avatar: (userImgUrl) || undefined,
          isBlockUserVal:isBlockUserVal
				}
			}

			if (message.replyMessage) {
        // eslint-disable-next-line no-mixed-spaces-and-tabs
			  if (!message.replyMessage.content) { message.replyMessage.content = '' }
				formattedMessage.replyMessage = {
					...message.replyMessage,
					...{
						senderId: message.replyMessage.sender_id
					}
				}
			}

			return formattedMessage
		},

    sendMessage: async function ({ content, roomId, files, replyMessage, senderUserId, system }) {
      // eslint-disable-next-line no-mixed-spaces-and-tabs
      const senderId = senderUserId || this.getMyMongoUserDbIdFn()
      const message = {
        sender_id: senderId,
        content,
        timestamp: new Date(),
        app_id: this.swingAppId,
        system: system
      }

      if (files) {
        message.files = this.formattedFiles(files)
      }

      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId
        }

        if (replyMessage.files) {
          message.replyMessage.files = replyMessage.files
        }
      }

      const userInfoArr = this.selectedRoomOb.users.filter((userInfo) => userInfo._id !== senderId)
      let userMongoDbIdArr = []
      userInfoArr.forEach(userInfo => {
        userMongoDbIdArr.push(userInfo._id)
      })
      if (files) {
        const fileCheck = this.uploadFileSizeCheckFn(files)
        if (fileCheck === false) {
          return false
        }
      }
      const { id } = await mongoService.addMessage(roomId, message, userMongoDbIdArr.toString())

      let fileUploadArr = []
      if (files) {
        for (let index = 0; index < files.length; index++) {
          if (message.files[index] && (!message.files[index]['swingFileId'] && !files[index]['swingFileId'])) {
            // eslint-disable-next-line camelcase
            const lp_fileInfo = await this.uploadFile({ file: files[index], messageId: id, roomId })
            fileUploadArr.push(lp_fileInfo)
          }
        }
      }

      let userStringIdArr = []
      let userDbIdArr = []
      userInfoArr.forEach(userInfo => {
        userStringIdArr.push(userInfo.userStringId)
        userDbIdArr.push(userInfo.userDbId)
      })
      // await this.sendSwingPushFn(userStringIdArr, ('채팅 ' + content))
      this.sendSwingPushFn(userDbIdArr, (content || ''), this.selectedRoomOb, this.MyUserInfo.userDbId, this.MyUserInfo.username, (fileUploadArr.length > 0 ? fileUploadArr.toString() : undefined)).then(d => {
      })

      // backEnd에서 업데이트하자
      // mongoService.updateRoom(roomId, { app_id: this.swingAppId, lastUpdated: new Date() })
    },

		async editMessage({ messageId, newContent, roomId, files }) {
      if (files) {
        const fileCheck = this.uploadFileSizeCheckFn(files)
        if (fileCheck === false) {
          return false
        }
      }

			const newMessage = { edited: new Date() }
			newMessage.content = newContent

			if (files) {
				newMessage.files = this.formattedFiles(files)
			} else {
				newMessage.files = []
			}

			await mongoService.updateMessage(roomId, messageId, newMessage)

			if (files) {
				for (let index = 0; index < files.length; index++) {
					if (files[index]?.blob && !newMessage.files[index]['swingFileId']) {
						await this.uploadFile({ file: files[index], messageId, roomId })
					}
				}
			}
		},

		async deleteMessage({ message, roomId }) {
			await mongoService.updateMessage(roomId, message._id, {
				deleted: new Date()
			})

			const { files } = message

			if (files) {
				files.forEach(file => {
          // eslint-disable-next-line no-mixed-spaces-and-tabs
				  if (file.swingFileId) {
            fileuploadS3Service.updateStorageDeltYn(this.swingAppId, this.MyUserInfo.userDbId, file.swingFileId, 'Y')
          }
				})
			}
		},

		async uploadFile({ file, messageId, roomId }) {
			return new Promise(resolve => {
				let type = file.type || file.extension

        const fileSize = file.size
        const maxFileSiezeMb = 10
        const maxFileSize = maxFileSiezeMb * 1024 * 1024

        if (maxFileSize < fileSize) {
          this.$toast.info(getI18NFn(this.langCode, 'm028', maxFileSiezeMb))
          resolve(false)
          return
        }

        fileuploadS3Service.fileUploadS3Fn(
          file,
          type,
          messageId,
          roomId,
          progress => {
            console.log(progress)
            this.updateFileProgress(messageId, file.localUrl, progress)
          },
          _error => {
            resolve(false)
          },
          async ({ url, swingFileId }) => {
            const message = await mongoService.getMessage(roomId, messageId)

            message.files.forEach(f => {
              if (f.url === file.localUrl) {
                f.url = url
                f.swingFileId = swingFileId
              }
            })

            // const myMessageItem = this.messages.find(message => message._id === messageId)
            // if (myMessageItem && myMessageItem.files) {
            //   const tempUrl = file.localUrl
            //   const tempFileOb = myMessageItem.files.find(file => file.url === tempUrl)
            //   if (tempFileOb) {
            //     tempFileOb.url = url
            //     delete tempFileOb.progress
            //     this.messages = [...this.messages]
            //   }
            // }
            await mongoService.updateMessage(roomId, messageId, {
              files: message.files
            })
            resolve(url)
          }
        )
			})
		},

    // eslint-disable-next-line vue/no-unused-properties
    uploadFileSizeCheckFn(files) {
      // eslint-disable-next-line no-mixed-spaces-and-tabs
		  let result = true
      // eslint-disable-next-line no-mixed-spaces-and-tabs
		  if (files && files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          const fileOb = files[index]
          const fileSize = fileOb.size
          const maxFileSiezeMb = 10
          const maxFileSize = maxFileSiezeMb * 1024 * 1024

          if (maxFileSize < fileSize) {
            this.$toast.info(getI18NFn(this.langCode, 'm028', maxFileSiezeMb))
            result = false
            break
          }
        }
      }
      // eslint-disable-next-line no-mixed-spaces-and-tabs
		  return result
    },

		updateFileProgress(messageId, fileUrl, progress) {
			const message = this.messages.find(message => message._id === messageId)

			if (!message || !message.files) return

			message.files.find(file => file.url === fileUrl).progress = progress
			this.messages = [...this.messages]
		},

		formattedFiles(files) {
      // eslint-disable-next-line no-mixed-spaces-and-tabs
		  const thisOb = this
			const formattedFiles = []
      let idx = 0

			files.forEach(file => {
				const messageFile = {
					name: file.name,
					size: file.size,
					type: file.type,
					extension: file.extension || file.type,
					url: file.url || file.localUrl
				}

				if (file.audio) {
					messageFile.audio = true
					messageFile.duration = file.duration
				}

        let el = thisOb.$refs.chatWindow.shadowRoot.querySelectorAll('.vac-files-box .vac-room-file-container')[idx]
        if (el) {
          let swingFileId = el.getAttribute('swing-file-id')
          let swingFileUrl = el.getAttribute('swing-file-url')
          if (swingFileId && swingFileUrl) {
            messageFile.swingFileId = swingFileId
            messageFile.url = swingFileUrl
          }
        }
        formattedFiles.push(messageFile)
        idx++
			})

			return formattedFiles
		},

    openFileRoot({ message, file }) {
      // eslint-disable-next-line no-mixed-spaces-and-tabs
      if (this.mediaPreviewEnabledVal === true) {
        console.log('open-file')
        window.open(file.file.url, '_blank')
      } else {
        //  file.action === 'preview' download
        console.log(`openFileEvent => ${file.file.url} ${file.action}`)
        window.parent.postMessage({
          function: 'openFileEvent',
          fileUrl: file.file.url,
          action: file.action
        }, '*')
      }
		},
    // eslint-disable-next-line vue/no-unused-properties
		// async loadRoom(query) {
		// 	query.forEach(async room => {
		// 		if (this.loadingRooms) return
		// 		await firestoreService.updateRoom(room.id, { lastUpdated: new Date() })
		// 		this.roomId = room.id
		// 		this.fetchRooms()
		// 	})
		// },

		menuActionHandler({ action, roomId }) {
			switch (action.name) {
				case 'inviteUser':
					return this.inviteUser(roomId)
				case 'removeUser':
					return this.removeUser(roomId)
				case 'deleteRoom':
					return this.deleteRoom(roomId)
			}
		},

		messageSelectionActionHandler({ action, messages, roomId }) {
			switch (action.name) {
				case 'deleteMessages':
					messages.forEach(message => {
						this.deleteMessage({ message, roomId })
					})
			}
		},

		async sendMessageReaction({ reaction, remove, messageId, roomId }) {
			// firestoreService.updateMessageReactions(
			// 	roomId,
			// 	messageId,
			// 	this.getMyMongoUserDbIdFn(),
			// 	reaction.unicode,
			// 	remove ? 'remove' : 'add'
			// )
		},

		typingMessage({ message, roomId }) {
      // eslint-disable-next-line no-constant-condition,no-mixed-spaces-and-tabs
		  if (true) {
        return
      }
      console.log('typingMessage')
			if (roomId) {
				if (message?.length > 1) {
					this.typingMessageCache = message
					return
				}

				if (message?.length === 1 && this.typingMessageCache) {
					this.typingMessageCache = message
					return
				}

				this.typingMessageCache = message

				// firestoreService.updateRoomTypingUsers(
				// 	roomId,
				// 	this.getMyMongoUserDbIdFn(),
				// 	message ? 'add' : 'remove'
				// )
        this.$socket.emit('typingMessage', {
          room_id: roomId,
          app_id: this.swingAppId,
          mongo_db_id: this.getMyMongoUserDbIdFn(),
          type_code: message ? 'add' : 'remove'
        })
			}
		},

    // eslint-disable-next-line vue/no-unused-properties
		async listenRooms(query) {
			// const listener = firestoreService.listenRooms(query, rooms => {
			// 	// this.incrementDbCounter('Listen Rooms Typing Users', rooms.length)
			// 	rooms.forEach(room => {
			// 		const foundRoom = this.rooms.find(r => r.roomId === room.id)
			// 		if (foundRoom) {
			// 			foundRoom.typingUsers = room.typingUsers
			// 			foundRoom.index = room.lastUpdated.seconds
			// 		}
			// 	})
			// })
			// this.roomsListeners.push(listener)
		},

		listenUsersOnlineStatus(rooms) {
			// rooms.forEach(room => {
			// 	room.users.forEach(user => {
			// 		const listener = firebaseService.firebaseListener(
			// 			firebaseService.userStatusRef(user._id),
			// 			snapshot => {
			// 				if (!snapshot || !snapshot.val()) return
      //
			// 				const lastChanged = formatTimestamp(
			// 					new Date(snapshot.val().lastChanged),
			// 					new Date(snapshot.val().lastChanged)
			// 				)
      //
			// 				user.status = { ...snapshot.val(), lastChanged }
      //
			// 				const roomIndex = this.rooms.findIndex(
			// 					r => room.roomId === r.roomId
			// 				)
      //
			// 				this.rooms[roomIndex] = room
			// 				this.rooms = [...this.rooms]
			// 			}
			// 		)
			// 		this.roomsListeners.push(listener)
			// 	})
			// })
		},

		addRoom() {
			this.resetForms()
			this.addNewRoom = true
		},

    // eslint-disable-next-line vue/no-unused-properties
		async createRoom() {
			this.disableForm = true

      const params = new URLSearchParams(window.location.search)

      const appId = params.get('app_id')

			await mongoService.addRoom({
        my_id: this.getMyMongoUserDbIdFn(),
        target_user_string_id: this.addRoomUsername,
        app_id: appId
			})

			this.addNewRoom = false
			this.addRoomUsername = ''
			this.fetchRooms()
		},

		inviteUser(roomId) {
			this.resetForms()
			this.inviteRoomId = roomId
		},

    // eslint-disable-next-line vue/no-unused-properties
		async addRoomUser() {
			this.disableForm = true

			const { _id } = await mongoService.addUser({
				username: this.invitedUsername,
        appId: this.swingAppId
			})
			// await firestoreService.updateUser(id, { _id: id })

      const roomId = this.inviteRoomId
			await mongoService.addRoomUser(this.inviteRoomId, _id, this.swingAppId)

			this.inviteRoomId = null
			this.invitedUsername = ''
			// this.fetchRooms()
		},

		removeUser(roomId) {
			this.resetForms()
			this.removeRoomId = roomId
			this.removeUsers = this.rooms.find(room => room.roomId === roomId).users
		},

    // eslint-disable-next-line camelcase
		async deleteRoomUser(p_roomId, p_userId, p_appId) {
			this.disableForm = true

      // eslint-disable-next-line camelcase
      let d_roomId = this.removeRoomId
      // eslint-disable-next-line camelcase
      let d_userId = this.removeUserId
      // eslint-disable-next-line camelcase
      let d_appId = this.swingAppId

      // eslint-disable-next-line camelcase
      if (p_roomId) { d_roomId = p_roomId }

      // eslint-disable-next-line camelcase
      if (p_userId) { d_userId = p_userId }

      // eslint-disable-next-line camelcase
      if (p_appId) { d_appId = p_appId }

			await mongoService.removeRoomUser(
        d_roomId,
        d_userId,
        d_appId
			)

			this.removeRoomId = null
			this.removeUserId = ''
			// this.fetchRooms()
		},

		async deleteRoom(roomId) {
			const room = this.rooms.find(r => r.roomId === roomId)

      mongoService.getMessages(roomId).then(({ data }) => {
				data.forEach(message => {
          mongoService.deleteMessage(roomId, message.id)
					if (message.files) {
						message.files.forEach(file => {
              if (file.swingFileId) {
                fileuploadS3Service.updateStorageDeltYn(this.swingAppId, this.MyUserInfo.userDbId, file.swingFileId, 'Y')
              }
						})
					}
				})
			})

			await mongoService.deleteRoom(this.swingAppId, roomId, this.getMyMongoUserDbIdFn())

			this.fetchRooms()
		},

		resetForms() {
			this.disableForm = false
			this.addNewRoom = null
			this.addRoomUsername = ''
			this.inviteRoomId = null
			this.invitedUsername = ''
			this.removeRoomId = null
			this.removeUserId = ''
		},

		// ,incrementDbCounter(type, size) {
		// 	size = size || 1
		// 	this.dbRequestCount += size
		// 	console.log(type, size)
		// }

    messageActionHandler({ roomId, action, message }) {
      switch (action.name) {
        case 'reportMessage':
          this.reportMsgFn(message)
        // call a method to add a message to the favorite list
        //  this.$toast.info(`신고<br />id : ${message.id}<br />content : ${message.content}<br />senderId : ${message.senderId}<br />username : ${message.username}`, { duration: 5000 })

        case 'blockUserMessage':
          // this.$toast.info(`<!--차단<br />roomId : ${roomId}<br />senderId : ${message.senderId}-->`, { duration: 5000 })
          this.blockUserFn(roomId, message.username, message.senderId);
      }
    },

    // eslint-disable-next-line vue/no-unused-properties
    parentGroupUserCnt(users) {
      if (users) {
        window.parent.postMessage({
          function: 'group_chat_member',
          member: JSON.parse(JSON.stringify(users))
        }, '*')
      }
    },

    // eslint-disable-next-line vue/no-unused-properties
    async addCss() {
      // eslint-disable-next-line eqeqeq
      if (process.env.NODE_ENV != 'production') {
        const styles = await import('../styles/index.scss')
        const style = document.createElement('style')
        style.innerHTML = styles.default
        this.$refs.chatWindow.shadowRoot.appendChild(style)
      }

      const styles = await import('../styles/index.scss')
      const style = document.createElement('style')
      // const addStyles = await import('./addCss.css')
      const addStyle = document.createElement('style')
      // addStyle.innerHTML = addStyles.default
      addStyle.innerHTML = '.vac-message-wrapper .vac-avatar{' +
        '    align-self: flex-start !important;' +
        '    height: 40px;' +
        '    width: 40px;' +
        '}' +
        '.vac-message-container-offset {' +
        '    margin-top: 0px !important; ' +
        // '}' +
        // '.vac-card-window {' +
        // ' height: 100vh !important' +
        '}' +
        '.vac-room-container .vac-message-new {' +
        // '   color:red;' +
        '}' +
        '.vac-room-container .vac-message-new:after {' +
        '    color: #fff;' +
        '    content: "N";' +
        '    border-radius: 9px;' +
        '    width: 9px;' +
        '    height: 9px;' +
        '    text-align: center;' +
        '    border: 1px solid red;' +
        '    padding: 2px;' +
        '    line-height: 9px;' +
        '    background-color: red;' +
        '    margin: 2px;' +
        '    margin-right: 5px;' +
        '    font-size: 10px;' +
        '}' +
        '.vac-message-wrapper .vac-avatar{' +
        '   min-width:40px!important;' +
        '}'

      if (this.gohostModeYn === 'Y') {
        addStyle.innerHTML += '#room-footer{display:none !important;}'
      }

      this.$refs.chatWindow.shadowRoot.appendChild(addStyle)
    },
    // eslint-disable-next-line vue/no-unused-properties
    firstRoomGotoEventFn(roomId) {
      // this.$toast.info('입장 : ' + roomId)
      try {
        console.log(`{"chat_unique_id":"${roomId}"}`)
        window.parent.postMessage({
          function: 'chat_room_info',
          data: `{"chat_unique_id":"${roomId}"}`
        }, '*')
      } catch (e) {
        console.log(e)
      }
    },

    // eslint-disable-next-line vue/no-unused-properties,camelcase
    getMyMongoUserDbIdFn(p_targetMongoUserId) {
      // eslint-disable-next-line no-mixed-spaces-and-tabs
		  let resultMongoUserDbId = this.currentUserId
      // eslint-disable-next-line camelcase
      if (p_targetMongoUserId) {
        // eslint-disable-next-line camelcase
        resultMongoUserDbId = p_targetMongoUserId
      }
      return resultMongoUserDbId
    },

    // eslint-disable-next-line vue/no-unused-properties
    getSelectedRoomTypeFn() {
      const roomType = this.swingRoomType || (this.selectedRoomOb && this.selectedRoomOb.roomType)
      return roomType
    },
    async reportMsgFn(message) {
      // eslint-disable-next-line no-mixed-spaces-and-tabs
		  // p_targetUserId, p_reportContent, p_reportUserId, p_reportObjectType, p_reportObjectId, p_reportMeta, p_stat, p_reportType

      const reportContentInput = prompt(getI18NFn(this.langCode, 'm027'))

      const targetUserInfoMap = this.selectedRoomOb.users.find(u => u._id === message.senderId)
      const reportUserInfoMap = this.selectedRoomOb.users.find(u => u._id === this.getMyMongoUserDbIdFn())
      const targetUserId = targetUserInfoMap.userDbId

      const reportContent = message.content
      const reportUserId = reportUserInfoMap.userDbId
      const reportObjectType = this.getSelectedRoomTypeFn()

      let reportObjectId
      if (reportObjectType === 'BOARD_CHAT' || reportObjectType === 'ADMIN_CHAT') {
        reportObjectId = this.selectedRoomOb.swingRoomDbId
      } else if (reportObjectType === 'USER_CHAT') {
        reportObjectId = message.roomId
      }

      const reportMetaOb = { msg_id: message.id, room_id: message.roomId }
      const reportMetaStr = JSON.stringify(reportMetaOb)

      const stat = 'I'
      const reportType = 'R'
      const ogContent = reportContent

      this.swomgAppReportFn(targetUserId, reportContentInput, reportUserId, reportObjectType, reportObjectId, reportMetaStr, stat, reportType, ogContent)
        .then((result) => {
          let infoStr = getI18NFn(this.langCode, 'm026')
          if (result && result.reportId) {
            // result.reportId
            infoStr = getI18NFn(this.langCode, 'm025')
          }
          this.$toast.info(`${infoStr}<br />msg_id : ${message.id}`)
        })
    },


    async blockUserFn(targetRoomId, targetUserName, targetUserId) {
      if(this.currentUserId === targetUserId){
        this.$toast.info(getI18NFn(this.langCode, 'm031'), { duration: 5000 } );
        return ;
      }

      const blockUserListKey = "B_USER_LIST";
      let userListObStr = localStorage.getItem(blockUserListKey);
      let userListOb = undefined;
      try{
        if(userListObStr && userListObStr!=null){
          userListOb = JSON.parse(userListObStr);
        }
      }catch (e){

      }
      if(!userListOb){
        userListOb={};
      }
      if(!userListOb[targetRoomId]){
        userListOb[targetRoomId]=[];
      }
      userListOb[targetRoomId].push(targetUserId);

      let saveData = JSON.stringify(userListOb);
      localStorage.setItem(blockUserListKey, saveData);


      if(this.messages){
        let msgList = [];
        this.messages.forEach((lp_item) => {
           if(lp_item.senderId !== targetUserId){
             msgList.push(lp_item);
           }
        })
        //
        // msgList.forEach((lp_item) => {
        //   this.$toast.info(lp_item.content, { duration: 5000 } );
        // })

        let tempVal = this.messagesLoaded;
        this.messagesLoaded = false
        this.messages = []
        this.messages = [...msgList];
        this.messagesLoaded = tempVal;
      }
    },

    isBlockUserFn(targetRoomId, targetUserId){
      let result = false;
      const blockUserListKey = "B_USER_LIST";
      let userListObStr = localStorage.getItem(blockUserListKey);
      let userListOb = undefined;
      try{
        if(userListObStr && userListObStr!=null){
          userListOb = JSON.parse(userListObStr);
        }
      }catch (e){

      }

      if(userListOb && userListOb[targetRoomId]){
        let userList = userListOb[targetRoomId];
        if(userList && userList.length>0){
          const userArr = userList.filter((lp_id) => lp_id === targetUserId);
          if(userArr && userArr.length>0){
            result=true;
          }
        }
      }

      return result;
    },

    getAllUserMapFn() {
		  return this.allUserMap
    },
    getSelectedRoomIdFn() {
      return this.selectedRoom
    }
	}
}
</script>

<style lang="scss" scoped>
.window-container {
	width: 100%;

  .vac-avatar{
    align-self: flex-start !important;
  }

}

.window-mobile {
	form {
		padding: 0 10px 10px;
	}
}

form {
	padding-bottom: 20px;
}

input {
	padding: 5px;
	width: 140px;
	height: 21px;
	border-radius: 4px;
	border: 1px solid #d2d6da;
	outline: none;
	font-size: 14px;
	vertical-align: middle;

	&::placeholder {
		color: #9ca6af;
	}
}

button {
	background: #1976d2;
	color: #fff;
	outline: none;
	cursor: pointer;
	border-radius: 4px;
	padding: 8px 12px;
	margin-left: 10px;
	border: none;
	font-size: 14px;
	transition: 0.3s;
	vertical-align: middle;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.6;
	}

	&:disabled {
		cursor: initial;
		background: #c6c9cc;
		opacity: 0.6;
	}
}

.button-cancel {
	color: #a8aeb3;
	background: none;
	margin-left: 5px;
}

select {
	vertical-align: middle;
	height: 33px;
	width: 152px;
	font-size: 13px;
	margin: 0 !important;
}
@media (min-width: 500px) {
  .vac-emoji-picker-sssssss{margin-right: -50px;}
}

</style>
